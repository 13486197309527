import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function MediaPlacementIcon({fill}) {
  return (
    <SvgIcon version="1.0" xmlns="http://www.w3.org/2000/svg"
    style={{
      width:'60%',
      height:'60%'
    }}
  viewBox="0 0 512 512"
 preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#439553" stroke="none">
<path d="M2020 5026 c-269 -58 -487 -263 -557 -526 -28 -103 -23 -293 9 -392
39 -119 92 -204 182 -294 129 -129 278 -198 454 -210 435 -31 797 329 768 764
-6 107 -43 228 -97 320 -49 82 -180 211 -262 257 -148 82 -343 113 -497 81z
m246 -157 c96 -17 213 -79 286 -152 112 -112 163 -235 163 -397 0 -160 -51
-285 -161 -394 -109 -110 -234 -161 -394 -161 -162 0 -285 51 -397 163 -237
237 -207 646 63 838 139 99 279 132 440 103z"/>
<path d="M1945 4615 l-25 -24 0 -271 0 -271 25 -24 c14 -15 35 -25 52 -25 15
1 145 60 288 133 221 112 261 136 269 160 22 63 12 72 -269 214 -143 73 -273
132 -288 133 -17 0 -38 -10 -52 -25z m345 -295 c0 -3 -47 -29 -105 -58 l-105
-52 0 110 0 110 105 -52 c58 -29 105 -55 105 -58z"/>
<path d="M3680 4947 c-49 -16 -133 -102 -148 -153 -7 -23 -12 -92 -12 -153 0
-105 1 -113 25 -136 15 -16 36 -25 55 -25 19 0 40 9 55 25 24 23 25 31 25 135
0 104 1 112 25 135 l24 25 511 0 511 0 24 -25 25 -24 0 -511 0 -511 -25 -24
-24 -25 -511 0 -511 0 -24 25 -25 24 0 271 0 271 -25 24 c-15 16 -36 25 -55
25 -19 0 -40 -9 -55 -25 l-25 -24 0 -272 c0 -184 4 -285 12 -313 16 -55 99
-138 154 -154 30 -9 181 -12 556 -12 590 0 578 -2 651 80 24 26 49 66 55 88
17 56 17 1048 0 1104 -6 22 -31 62 -55 88 -73 82 -60 80 -657 79 -349 -1 -533
-5 -556 -12z"/>
<path d="M4240 4627 c-49 -16 -133 -102 -148 -153 -7 -23 -12 -67 -12 -98 l0
-56 -55 0 c-67 0 -105 -29 -105 -80 0 -51 38 -80 105 -80 l55 0 0 -135 c0
-131 1 -137 25 -160 15 -16 36 -25 55 -25 19 0 40 9 55 25 24 23 25 29 25 160
l0 135 55 0 c67 0 105 29 105 80 0 51 -38 80 -105 80 l-55 0 0 55 c0 46 4 60
25 80 23 24 31 25 135 25 104 0 112 1 135 25 16 15 25 36 25 55 0 19 -9 40
-25 55 -23 24 -29 25 -142 24 -65 -1 -134 -6 -153 -12z"/>
<path d="M185 4215 l-25 -24 0 -671 0 -671 25 -24 24 -25 511 0 511 0 24 25
25 24 0 671 0 671 -25 24 -24 25 -511 0 -511 0 -24 -25z m935 -695 l0 -560
-400 0 -400 0 0 560 0 560 400 0 400 0 0 -560z"/>
<path d="M665 3895 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 23 -24 31
-25 135 -25 104 0 112 1 135 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -23 24
-31 25 -135 25 -104 0 -112 -1 -135 -25z"/>
<path d="M505 3575 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 24 -25 26
-25 215 -25 189 0 191 0 215 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -24 25
-26 25 -215 25 -189 0 -191 0 -215 -25z"/>
<path d="M505 3255 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 24 -25 26
-25 215 -25 189 0 191 0 215 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -24 25
-26 25 -215 25 -189 0 -191 0 -215 -25z"/>
<path d="M3157 3748 c-9 -7 -75 -91 -147 -187 -141 -190 -150 -211 -105 -256
26 -27 71 -33 98 -12 9 6 75 90 147 186 141 190 150 211 105 256 -26 27 -71
33 -98 13z"/>
<path d="M3319 3230 c-96 -72 -180 -138 -186 -147 -21 -27 -15 -72 12 -98 45
-45 66 -36 256 105 96 72 180 138 187 147 20 27 14 72 -13 98 -45 45 -66 36
-256 -105z"/>
<path d="M2545 3268 c-11 -6 -270 -218 -577 -471 -552 -455 -558 -459 -740
-564 -202 -116 -246 -152 -286 -233 -23 -47 -27 -67 -27 -140 l1 -86 -59 -34
c-102 -60 -153 -166 -128 -265 29 -115 119 -185 237 -185 52 0 73 6 128 35
l66 35 41 -29 c100 -73 224 -80 339 -20 36 19 68 35 72 37 3 2 41 -59 85 -135
43 -76 94 -156 113 -177 93 -104 257 -104 351 1 47 52 62 100 57 177 -3 55
-12 75 -86 205 -45 79 -80 146 -78 149 3 2 274 105 603 229 615 230 643 243
643 295 0 20 -369 672 -417 736 -20 26 -31 32 -63 32 -30 0 -43 -6 -59 -26
-38 -49 -49 -25 176 -413 90 -155 163 -285 160 -287 -2 -2 -298 -114 -657
-249 -646 -242 -655 -246 -824 -344 -94 -54 -185 -102 -203 -105 -46 -10 -104
9 -139 46 -17 18 -68 98 -114 178 -91 158 -106 210 -77 266 23 45 52 66 201
153 l139 80 106 -182 c115 -198 133 -216 190 -196 35 12 51 35 51 74 0 15 -44
102 -105 207 -58 100 -104 185 -103 189 2 4 147 125 323 271 176 145 398 327
493 406 163 134 192 155 192 133 0 -22 68 -81 94 -81 36 0 76 41 76 77 0 32
-80 179 -111 206 -24 20 -55 22 -84 5z m-1513 -1706 l37 -68 -42 -22 c-58 -30
-100 -29 -126 4 -37 46 -24 88 37 129 20 13 42 24 47 24 6 1 27 -30 47 -67z
m947 -202 c97 -168 103 -210 32 -240 -53 -22 -77 0 -168 155 -45 77 -82 145
-82 150 -1 11 106 74 125 75 7 0 48 -63 93 -140z"/>
<path d="M3605 2836 c-110 -23 -207 -46 -217 -50 -22 -10 -41 -60 -33 -86 10
-31 45 -60 74 -60 14 0 120 19 236 42 170 34 214 46 232 64 49 49 11 135 -59
133 -18 -1 -123 -20 -233 -43z"/>
<path d="M425 2535 l-25 -24 0 -856 0 -855 -95 0 c-90 0 -98 -2 -122 -26 -25
-25 -25 -26 -21 -167 3 -134 5 -146 35 -209 44 -93 100 -151 191 -196 l76 -37
1136 0 1136 0 76 37 c91 45 147 103 191 196 30 63 32 75 35 209 4 141 4 142
-21 167 -24 24 -32 26 -122 26 l-95 0 0 404 c0 393 -1 404 -21 430 -29 37 -89
37 -118 0 -20 -26 -21 -37 -21 -430 l0 -404 -1040 0 -1040 0 0 800 0 800 344
0 345 0 27 23 c20 17 27 33 27 57 0 24 -7 40 -27 57 l-27 23 -400 0 -400 0
-24 -25z m2455 -1951 c0 -87 -18 -133 -75 -189 -61 -62 -101 -75 -229 -75
l-96 0 0 55 c0 67 -29 105 -80 105 -51 0 -80 -38 -80 -105 l0 -55 -720 0 -720
0 0 55 c0 67 -29 105 -80 105 -51 0 -80 -38 -80 -105 l0 -55 -96 0 c-128 0
-168 13 -229 75 -57 56 -75 102 -75 189 l0 56 1280 0 1280 0 0 -56z"/>
<path d="M3680 2467 c-49 -16 -133 -102 -148 -153 -17 -59 -17 -1049 0 -1108
16 -55 99 -138 154 -154 30 -9 181 -12 556 -12 590 0 578 -2 651 80 56 62 67
102 67 239 0 105 -1 113 -25 136 -32 33 -78 33 -110 0 -24 -23 -25 -31 -25
-135 0 -104 -1 -112 -25 -135 l-24 -25 -511 0 -511 0 -24 25 -25 24 0 511 0
511 25 24 24 25 511 0 511 0 24 -25 25 -24 0 -271 0 -271 25 -24 c15 -16 36
-25 55 -25 19 0 40 9 55 25 l25 24 0 272 c0 181 -4 285 -12 311 -6 22 -31 62
-55 88 -73 82 -60 80 -657 79 -349 -1 -533 -5 -556 -12z"/>
<path d="M4165 2071 c-92 -24 -173 -90 -215 -176 -35 -73 -35 -199 0 -270 31
-64 91 -124 155 -155 71 -35 197 -35 270 0 64 31 124 91 155 155 35 73 35 199
0 270 -32 64 -92 124 -155 154 -54 25 -156 36 -210 22z m138 -165 c103 -43
128 -177 48 -257 -65 -65 -157 -65 -222 0 -124 123 13 325 174 257z"/>
</g>
 </SvgIcon>
  );
}