import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function ConceptDevelopmentIcon() {
    return (
        <SvgIcon
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                width: '60%',
                height: '60%'
            }}
            viewBox="0 0 512 512"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#439553" stroke="none">
                <path d="M4701 4949 c-60 -18 -177 -110 -168 -132 6 -13 277 -330 289 -337 14
-9 131 104 149 144 22 49 25 143 6 189 -20 47 -64 95 -107 117 -46 24 -124 32
-169 19z"/>
                <path d="M3851 4249 c-356 -305 -559 -485 -554 -492 29 -48 289 -337 299 -333
7 3 263 219 569 481 l555 477 -66 76 c-36 42 -102 121 -147 174 -45 53 -85 97
-89 97 -3 1 -259 -215 -567 -480z"/>
                <path d="M2281 4116 l-114 -114 -86 -12 c-652 -88 -1198 -521 -1437 -1140
-119 -309 -146 -684 -73 -1015 79 -359 286 -713 556 -949 130 -114 307 -230
351 -230 41 0 62 24 62 70 0 36 6 31 -135 123 -301 197 -541 512 -654 858
-107 327 -107 696 0 1023 191 589 696 1017 1313 1115 l99 16 117 -115 c98 -98
122 -116 148 -116 34 0 72 38 72 72 0 11 -30 51 -67 89 l-66 69 55 0 c127 0
364 -54 520 -120 88 -36 123 -36 151 1 31 43 14 78 -55 111 -150 72 -395 134
-570 145 l-99 6 59 61 c66 68 79 94 61 128 -16 31 -28 38 -64 38 -25 0 -49
-19 -144 -114z"/>
                <path d="M3187 3618 c-8 -18 -42 -95 -76 -171 -33 -76 -61 -146 -61 -156 0
-23 31 -51 57 -51 19 0 342 96 350 104 2 2 -55 72 -126 155 l-130 152 -14 -33z"/>
                <path d="M2151 3331 c-10 -10 -21 -41 -25 -71 -17 -128 -109 -217 -232 -228
-64 -5 -115 11 -175 54 -77 56 -95 51 -210 -65 -114 -113 -121 -135 -69 -195
86 -98 82 -252 -9 -344 -45 -45 -83 -62 -155 -73 -92 -12 -96 -21 -96 -191 0
-134 1 -140 25 -163 15 -16 35 -25 56 -25 56 0 110 -23 159 -67 57 -51 81
-105 81 -183 1 -65 -18 -116 -61 -168 -50 -61 -44 -81 61 -188 51 -51 104 -97
119 -102 29 -11 61 1 119 43 94 69 233 53 317 -37 43 -45 62 -85 70 -147 12
-93 19 -96 189 -96 170 0 177 3 189 96 8 62 27 102 70 147 84 90 223 106 317
37 58 -42 90 -54 119 -43 15 5 68 51 119 102 106 109 111 126 56 194 -42 54
-59 102 -58 167 2 72 28 129 83 178 49 44 103 67 159 67 21 0 41 9 56 25 24
23 25 29 25 163 0 170 -4 179 -96 191 -72 11 -110 28 -155 73 -92 92 -96 246
-9 344 52 60 45 82 -69 195 -115 116 -133 121 -210 65 -60 -43 -111 -59 -175
-54 -123 11 -215 100 -232 228 -11 86 -19 90 -189 90 -128 0 -147 -2 -164 -19z
m239 -631 c188 -30 347 -173 395 -355 64 -247 -65 -491 -310 -585 -86 -33
-234 -33 -320 0 -203 78 -325 251 -325 460 0 262 206 474 475 489 11 0 49 -4
85 -9z"/>
                <path d="M3650 3290 c-33 -33 -25 -70 37 -161 274 -407 347 -948 192 -1422
-186 -570 -673 -994 -1274 -1109 -107 -21 -137 -22 -1275 -25 -1288 -4 -1200
1 -1200 -67 0 -37 7 -49 37 -65 29 -15 2136 -16 2303 0 780 71 1422 634 1589
1394 73 331 46 706 -74 1015 -64 167 -212 427 -256 450 -28 16 -57 12 -79 -10z"/>
                <path d="M3981 814 c-12 -15 -21 -36 -21 -47 0 -11 36 -57 87 -108 l87 -89
-461 0 c-433 0 -461 -1 -476 -18 -17 -19 -22 -68 -9 -88 21 -32 53 -34 493
-34 l442 0 -76 -78 c-79 -82 -96 -116 -77 -152 13 -24 55 -43 79 -35 9 3 85
74 169 158 126 127 152 158 152 182 0 24 -26 55 -153 182 -132 132 -157 153
-184 153 -22 0 -38 -8 -52 -26z"/>
            </g>

        </SvgIcon>
    )
}