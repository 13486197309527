
import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function BrandIdentityIcon({fill}) {
  return (
    <SvgIcon version="1.0" xmlns="http://www.w3.org/2000/svg"
    style={{
      width:'60%',
      height:'60%'
    }}
  viewBox="0 0 512 512"
 preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#439553" stroke="none">
<path d="M190 5111 c-69 -21 -130 -73 -166 -141 -18 -34 -19 -101 -19 -1940 0
-1864 0 -1906 19 -1944 25 -50 72 -97 122 -122 38 -18 75 -19 1214 -22 646 -1
1191 0 1210 3 29 5 84 55 339 308 167 167 309 316 317 334 12 27 14 294 14
1686 l0 1653 -26 52 c-14 28 -43 65 -64 82 -81 64 15 60 -1529 59 -773 0
-1417 -4 -1431 -8z m2833 -173 c47 -25 46 -11 47 -616 l0 -572 -1448 2 -1447
3 -3 568 -2 568 29 30 29 29 1387 0 c1035 0 1392 -3 1408 -12z m47 -2292 l0
-934 -202 -4 -203 -3 -54 -30 c-58 -32 -90 -68 -118 -131 -14 -31 -18 -78 -21
-236 l-4 -198 -1120 0 -1120 0 -29 29 -29 29 0 1206 0 1206 1450 0 1450 0 0
-934z m-130 -1114 c0 -4 -65 -72 -145 -152 l-145 -145 0 126 c0 117 1 127 23
146 12 11 29 23 37 26 23 8 230 8 230 -1z"/>
<path d="M661 4764 c-211 -57 -349 -265 -313 -475 24 -142 110 -257 237 -320
67 -33 74 -34 185 -34 104 0 121 3 173 27 80 38 170 126 210 207 30 63 32 72
32 181 0 109 -2 119 -32 183 -87 184 -299 283 -492 231z m230 -190 c109 -60
155 -180 117 -308 -16 -55 -99 -138 -154 -154 -127 -37 -248 8 -307 116 -58
106 -42 218 42 302 84 84 197 100 302 44z"/>
<path d="M1413 4770 c-50 -20 -60 -105 -17 -139 26 -21 34 -21 693 -21 728 0
699 -2 722 56 13 35 -12 91 -46 104 -33 13 -1321 12 -1352 0z"/>
<path d="M1415 4428 c-51 -27 -62 -98 -22 -136 23 -21 30 -22 271 -22 l247 0
24 25 c37 36 34 86 -6 119 l-31 26 -231 0 c-149 0 -239 -5 -252 -12z"/>
<path d="M1430 4093 c-60 -22 -81 -100 -37 -141 23 -22 27 -22 400 -22 362 0
378 1 397 20 45 45 33 112 -25 135 -28 12 -99 15 -377 14 -189 0 -350 -3 -358
-6z"/>
<path d="M645 3318 c-51 -29 -61 -93 -20 -133 l24 -25 970 0 970 0 25 23 c28
24 34 48 23 89 -16 60 25 58 -1019 58 -698 -1 -958 -4 -973 -12z"/>
<path d="M795 2875 c-46 -45 -24 -125 38 -140 12 -3 377 -4 810 -3 l789 3 19
24 c27 33 25 86 -6 116 l-24 25 -801 0 -801 0 -24 -25z"/>
<path d="M815 2548 c-52 -28 -62 -92 -20 -133 l24 -25 801 0 801 0 24 25 c34
33 34 87 0 120 l-24 25 -793 0 c-555 -1 -799 -4 -813 -12z"/>
<path d="M815 2208 c-51 -30 -61 -93 -20 -133 l24 -25 799 0 c793 0 799 0 826
21 32 26 36 84 7 120 l-19 24 -799 2 c-590 2 -803 0 -818 -9z"/>
<path d="M801 1854 c-40 -33 -43 -83 -6 -119 l24 -25 804 0 804 0 21 23 c22
23 29 68 16 102 -18 45 -16 45 -848 45 l-784 0 -31 -26z"/>
<path d="M3586 5105 c-83 -29 -148 -103 -165 -188 -8 -36 -11 -346 -9 -1022
l3 -970 23 -47 c13 -27 42 -64 64 -83 79 -67 62 -66 784 -63 644 3 649 3 689
25 47 25 95 74 121 123 18 33 19 79 19 1045 l0 1010 -22 41 c-31 58 -104 119
-158 133 -30 7 -243 11 -677 11 -517 -1 -640 -3 -672 -15z m1335 -184 l29 -29
0 -967 0 -967 -29 -29 -29 -29 -616 0 c-383 0 -625 4 -641 10 -57 22 -56 -7
-53 1036 l3 956 28 24 28 24 625 0 626 0 29 -29z"/>
<path d="M3802 4770 c-50 -20 -53 -52 -50 -435 l3 -353 24 -26 c18 -19 35 -26
61 -26 26 0 43 7 61 26 l24 26 3 353 c3 388 0 415 -53 435 -31 12 -42 12 -73
0z"/>
<path d="M4465 4495 l-25 -24 0 -244 c0 -232 1 -246 21 -271 15 -19 30 -26 54
-26 89 0 95 17 95 295 0 229 -4 255 -39 282 -27 21 -79 15 -106 -12z"/>
<path d="M4125 4325 c-25 -24 -25 -27 -25 -188 0 -158 1 -164 23 -185 30 -28
89 -30 116 -3 28 28 33 69 29 220 -3 131 -3 133 -31 157 -37 31 -81 31 -112
-1z"/>
<path d="M4000 3741 c-84 -26 -170 -99 -210 -179 -84 -168 -7 -379 168 -460
81 -37 193 -38 273 0 78 36 134 89 168 162 39 81 47 147 26 227 -29 115 -104
200 -213 242 -44 18 -165 22 -212 8z m179 -182 c45 -28 67 -56 81 -105 32
-113 -79 -232 -193 -209 -84 18 -137 84 -137 173 0 124 143 206 249 141z"/>
<path d="M3584 2374 c-57 -21 -118 -75 -146 -132 l-23 -47 0 -1000 c0 -902 2
-1004 16 -1036 23 -51 75 -107 123 -132 l41 -22 670 0 c668 0 670 0 710 22 47
25 95 74 121 123 18 33 19 79 19 1045 0 966 -1 1012 -19 1045 -26 49 -74 98
-121 123 -40 22 -44 22 -695 24 -555 1 -661 -1 -696 -13z m1337 -183 l29 -29
0 -967 0 -967 -29 -29 -29 -29 -597 0 c-561 0 -597 1 -584 17 8 9 180 197 383
417 228 249 378 420 394 451 23 43 27 62 27 140 0 78 -4 97 -27 140 -16 31
-171 208 -407 463 -210 228 -381 416 -381 418 0 2 268 4 596 4 l596 0 29 -29z
m-970 -503 c199 -216 371 -406 381 -423 26 -43 23 -104 -7 -148 -24 -37 -732
-807 -740 -807 -3 0 -5 398 -5 885 0 487 2 885 5 885 2 0 167 -177 366 -392z"/>
<path d="M142 654 c-62 -31 -106 -81 -128 -147 -23 -69 -17 -307 10 -357 25
-49 74 -98 121 -123 l40 -22 1289 -3 c1132 -2 1297 0 1350 13 41 10 111 46
216 109 182 109 203 133 197 228 -2 41 -10 65 -29 90 -26 33 -235 165 -334
210 l-49 23 -1315 3 -1315 2 -53 -26z m458 -314 l0 -170 -186 0 -186 0 -29 29
c-28 29 -29 32 -29 136 0 106 8 138 39 162 11 9 72 12 204 13 l187 0 0 -170z
m1870 0 l0 -170 -850 0 -850 0 0 170 0 170 850 0 850 0 0 -170z m320 160 c19
-5 90 -43 158 -84 l123 -73 -98 -61 c-148 -91 -180 -104 -255 -110 l-68 -5 0
172 0 171 53 0 c28 0 68 -5 87 -10z"/>
</g>
 </SvgIcon>
  );
}


