import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function EventsIcon({fill}) {
  return (
    <SvgIcon version="1.0" xmlns="http://www.w3.org/2000/svg"
    style={{
      width:'60%',
      height:'60%'
    }}
  viewBox="0 0 512 512"
 preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#439553" stroke="none">
<path d="M1067 4256 c-82 -30 -107 -79 -107 -210 l0 -94 -187 -4 c-170 -3
-192 -5 -228 -25 -50 -27 -80 -58 -100 -105 -13 -33 -15 -194 -15 -1418 0
-1224 2 -1385 15 -1418 20 -47 50 -78 100 -105 l40 -22 1975 0 1975 0 45 25
c24 14 58 45 75 68 l30 44 3 1386 c2 966 0 1398 -8 1424 -14 49 -53 94 -104
121 -37 20 -58 22 -228 25 l-188 4 0 94 c0 75 -4 103 -20 133 -56 111 -216
114 -277 5 -19 -33 -23 -54 -23 -136 l0 -98 -320 0 -320 0 0 95 c0 76 -4 104
-20 134 -56 111 -216 114 -277 5 -19 -33 -23 -54 -23 -136 l0 -98 -320 0 -320
0 0 95 c0 76 -4 104 -20 134 -56 111 -216 114 -277 5 -19 -33 -23 -54 -23
-136 l0 -98 -320 0 -320 0 0 95 c0 76 -4 104 -20 134 -35 69 -121 103 -193 77z
m87 -112 c14 -13 16 -50 16 -253 0 -253 -1 -261 -50 -261 -49 0 -50 8 -50 261
0 203 2 240 16 253 8 9 24 16 34 16 10 0 26 -7 34 -16z m960 0 c14 -13 16 -50
16 -253 0 -253 -1 -261 -50 -261 -49 0 -50 8 -50 261 0 203 2 240 16 253 8 9
24 16 34 16 10 0 26 -7 34 -16z m960 0 c14 -13 16 -50 16 -253 0 -253 -1 -261
-50 -261 -49 0 -50 8 -50 261 0 203 2 240 16 253 8 9 24 16 34 16 10 0 26 -7
34 -16z m960 0 c14 -13 16 -50 16 -253 0 -253 -1 -261 -50 -261 -49 0 -50 8
-50 261 0 203 2 240 16 253 8 9 24 16 34 16 10 0 26 -7 34 -16z m-3072 -413
c3 -118 12 -141 72 -185 39 -29 133 -29 172 0 60 44 69 67 72 185 l4 109 318
0 318 0 4 -109 c3 -118 12 -141 72 -185 39 -29 133 -29 172 0 60 44 69 67 72
185 l4 109 318 0 318 0 4 -109 c3 -118 12 -141 72 -185 39 -29 133 -29 172 0
60 44 69 67 72 185 l4 109 318 0 318 0 4 -109 c3 -118 12 -141 72 -185 39 -29
133 -29 172 0 60 44 69 67 72 185 l4 109 169 0 c263 0 259 5 259 -315 l0 -215
-2030 0 -2030 0 0 214 c0 236 6 268 54 297 27 17 52 19 203 19 l171 0 4 -109z
m3628 -1601 c0 -1172 3 -1123 -59 -1154 -27 -14 -229 -16 -1971 -16 -1742 0
-1944 2 -1971 16 -62 31 -59 -18 -59 1154 l0 1070 2030 0 2030 0 0 -1070z"/>
<path d="M766 2544 c-14 -14 -16 -70 -16 -464 0 -394 2 -450 16 -464 13 -13
45 -16 194 -16 193 0 210 4 210 55 0 46 -31 55 -182 55 l-139 0 3 158 3 157
123 5 c126 5 142 11 142 50 0 39 -16 45 -142 50 l-123 5 -3 158 -3 157 139 0
c151 0 182 9 182 55 0 51 -17 55 -210 55 -149 0 -181 -3 -194 -16z"/>
<path d="M1406 2544 c-9 -8 -16 -26 -16 -38 0 -30 148 -821 161 -863 11 -34
36 -48 68 -38 11 3 24 20 30 38 23 73 163 857 157 880 -12 48 -77 47 -94 -1
-5 -15 -31 -145 -58 -289 -27 -145 -51 -263 -54 -263 -3 0 -27 118 -54 263
-27 144 -53 273 -58 287 -16 42 -53 53 -82 24z"/>
<path d="M2046 2544 c-14 -14 -16 -70 -16 -464 0 -394 2 -450 16 -464 13 -13
45 -16 194 -16 193 0 210 4 210 55 0 46 -31 55 -182 55 l-139 0 3 158 3 157
123 5 c126 5 142 11 142 50 0 39 -16 45 -142 50 l-123 5 -3 158 -3 157 139 0
c151 0 182 9 182 55 0 51 -17 55 -210 55 -149 0 -181 -3 -194 -16z"/>
<path d="M2686 2544 c-14 -14 -16 -70 -16 -464 0 -394 2 -450 16 -464 17 -18
42 -20 64 -6 13 8 16 56 20 302 l5 293 94 -250 c109 -292 128 -337 144 -347
19 -12 44 -9 61 8 14 14 16 70 16 464 0 394 -2 450 -16 464 -17 18 -42 20 -64
6 -13 -8 -16 -56 -20 -302 l-5 -293 -94 250 c-109 292 -128 337 -144 347 -19
12 -44 9 -61 -8z"/>
<path d="M3326 2544 c-9 -8 -16 -26 -16 -39 0 -36 36 -55 102 -55 l58 0 0
-409 c0 -359 2 -412 16 -425 20 -20 48 -20 68 0 14 13 16 66 16 425 l0 409 58
0 c66 0 102 19 102 55 0 51 -17 55 -210 55 -149 0 -181 -3 -194 -16z"/>
<path d="M4080 2544 c-46 -20 -87 -60 -112 -108 -17 -33 -19 -54 -16 -155 3
-106 5 -121 28 -154 37 -55 89 -87 158 -97 32 -4 69 -14 81 -21 32 -21 51 -71
51 -138 0 -106 -38 -161 -110 -161 -66 0 -110 56 -110 139 0 43 -20 71 -50 71
-10 0 -26 -7 -34 -16 -24 -23 -22 -134 3 -182 71 -139 248 -163 348 -49 45 51
55 96 51 218 -3 93 -6 110 -28 142 -37 55 -89 87 -158 97 -32 4 -69 14 -81 21
-32 21 -51 71 -51 138 0 106 38 161 110 161 66 0 110 -56 110 -139 0 -62 47
-93 84 -55 24 23 22 134 -3 182 -53 102 -172 149 -271 106z"/>
</g>

 </SvgIcon>
  );
}