import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function ReportingIcon({fill}) {
  return (
    <SvgIcon version="1.0" xmlns="http://www.w3.org/2000/svg"
    style={{
      width:'60%',
      height:'60%'
    }}
  viewBox="0 0 512 512"
 preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#439553" stroke="none">
<path d="M1405 5102 c-62 -21 -114 -60 -150 -111 -45 -67 -55 -118 -55 -301
l0 -167 -122 -6 c-68 -2 -159 -12 -203 -22 -375 -79 -689 -356 -811 -713 -174
-509 32 -1053 501 -1321 150 -86 363 -141 547 -141 l88 0 2 -1047 3 -1048 21
-46 c28 -60 93 -125 153 -153 l46 -21 1735 0 1735 0 46 21 c60 28 125 93 153
153 l21 46 3 1890 c1 1040 0 1906 -3 1925 -6 30 -81 110 -513 542 -278 279
-521 515 -538 523 -28 13 -191 15 -1321 15 -1195 -1 -1292 -2 -1338 -18z
m2515 -647 l0 -465 29 -32 29 -33 471 -3 471 -3 -2 -1829 -3 -1830 -28 -27
-27 -28 -1700 0 -1700 0 -27 28 -28 27 0 1051 0 1051 75 28 c154 56 280 138
403 262 137 137 231 305 285 507 22 85 25 116 25 261 0 145 -3 176 -25 261
-54 202 -148 370 -285 507 -123 124 -249 206 -403 263 l-75 27 0 190 c0 176 1
192 20 212 11 12 29 26 40 31 11 4 568 8 1238 8 l1217 1 0 -465z m530 -10
l325 -325 -328 0 -327 0 0 325 c0 179 1 325 3 325 1 0 148 -146 327 -325z
m-3448 -600 l3 -465 28 -27 27 -28 465 -3 465 -3 0 -22 c0 -13 -9 -58 -20
-101 -83 -316 -327 -561 -645 -647 -115 -32 -335 -32 -450 0 -312 85 -562 335
-646 646 -29 105 -32 325 -6 428 69 275 251 497 504 614 78 36 198 72 245 72
l27 1 3 -465z m324 445 c241 -61 467 -246 579 -475 43 -86 85 -222 85 -272 l0
-23 -395 0 -395 0 0 395 0 395 23 0 c13 0 59 -9 103 -20z"/>
<path d="M2524 4510 c-32 -13 -64 -59 -64 -91 0 -18 11 -41 29 -61 l29 -33
566 -3 565 -2 33 29 c27 24 33 36 33 71 0 35 -6 47 -33 71 l-32 29 -553 -1
c-303 0 -562 -4 -573 -9z"/>
<path d="M2524 4110 c-32 -13 -64 -59 -64 -91 0 -18 11 -41 29 -61 l29 -33
566 -3 565 -2 33 29 c27 24 33 36 33 71 0 35 -6 47 -33 71 l-32 29 -553 -1
c-303 0 -562 -4 -573 -9z"/>
<path d="M2464 3510 c-32 -13 -64 -59 -64 -91 0 -40 46 -88 91 -95 31 -5 42
-1 70 24 28 25 34 37 34 72 0 35 -6 47 -33 71 -33 29 -60 35 -98 19z"/>
<path d="M2864 3510 c-32 -13 -64 -59 -64 -91 0 -18 11 -41 29 -61 l29 -33
796 -3 795 -2 33 29 c27 24 33 36 33 71 0 35 -6 47 -33 71 l-32 29 -783 -1
c-430 0 -792 -4 -803 -9z"/>
<path d="M2464 3110 c-32 -13 -64 -59 -64 -91 0 -40 46 -88 91 -95 31 -5 42
-1 70 24 28 25 34 37 34 72 0 35 -6 47 -33 71 -33 29 -60 35 -98 19z"/>
<path d="M2864 3110 c-32 -13 -64 -59 -64 -91 0 -18 11 -41 29 -61 l29 -33
796 -3 795 -2 33 29 c27 24 33 36 33 71 0 35 -6 47 -33 71 l-32 29 -783 -1
c-430 0 -792 -4 -803 -9z"/>
<path d="M4295 2443 c-169 -61 -248 -244 -176 -408 l20 -45 -343 -369 -344
-368 -73 1 c-41 1 -90 -3 -109 -7 l-35 -8 -152 190 -153 190 16 63 c28 110 2
210 -76 288 -206 206 -558 21 -501 -263 7 -34 13 -67 14 -73 1 -6 -78 -99
-177 -206 l-179 -195 -46 14 c-97 30 -215 -1 -291 -77 -61 -61 -85 -119 -85
-210 0 -91 24 -149 85 -210 206 -206 558 -21 501 263 -7 34 -13 67 -14 73 -1
6 78 99 177 206 l179 195 46 -14 c44 -14 116 -13 181 3 23 5 39 -12 177 -185
l153 -190 -16 -63 c-37 -145 31 -290 165 -351 64 -30 178 -30 242 0 60 27 125
92 152 152 30 65 30 178 0 244 l-22 47 343 368 342 367 85 0 c101 0 155 21
219 85 61 61 85 119 85 210 0 91 -24 149 -85 210 -80 80 -203 109 -305 73z
m165 -218 c35 -36 34 -95 -3 -132 -60 -61 -162 -19 -162 66 0 89 101 130 165
66z m-1730 -400 c38 -39 35 -98 -9 -137 -28 -25 -39 -29 -70 -24 -23 3 -46 17
-65 37 -23 26 -27 38 -23 67 14 86 106 117 167 57z m-768 -794 c67 -60 28
-166 -62 -166 -105 0 -132 146 -35 186 37 15 64 9 97 -20z m1468 -6 c58 -59
14 -160 -70 -160 -78 0 -126 90 -80 148 42 53 104 58 150 12z"/>
<path d="M465 3511 c-35 -15 -57 -49 -62 -94 -8 -71 23 -202 74 -307 39 -81
59 -108 133 -182 72 -72 103 -94 181 -131 97 -47 212 -77 295 -77 39 0 51 5
80 34 46 46 46 86 0 132 -28 28 -41 34 -78 34 -110 0 -252 62 -339 149 -87 87
-149 229 -149 339 0 37 -6 50 -34 78 -34 34 -61 41 -101 25z"/>
</g>
 </SvgIcon>
  );
}