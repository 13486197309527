import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function MediaStartIcon({fill}) {
  return (
    <SvgIcon version="1.0" xmlns="http://www.w3.org/2000/svg"
    style={{
      width:'60%',
      height:'60%'
    }}
  viewBox="0 0 512 512"
 preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#439553" stroke="none">
<path d="M1273 4892 c-98 -35 -190 -127 -225 -225 -15 -46 -17 -91 -18 -374
l0 -323 -275 0 c-226 0 -282 -3 -313 -15 -47 -20 -79 -50 -103 -97 -18 -35
-19 -64 -19 -470 0 -430 0 -434 22 -478 14 -26 41 -57 66 -75 l44 -30 289 -3
289 -3 0 -149 0 -149 26 -20 c34 -26 54 -26 88 0 l26 20 0 149 0 149 289 3
289 3 44 30 c23 17 54 51 68 75 l25 45 3 392 c4 432 -1 486 -47 543 -59 74
-63 74 -381 80 l-285 5 -3 208 -2 207 1565 0 1565 0 0 -80 0 -80 -272 0 c-267
0 -274 -1 -324 -24 -56 -26 -98 -74 -120 -133 -10 -30 -13 -146 -14 -534 0
-557 0 -557 71 -628 66 -66 96 -71 396 -71 l263 0 0 -299 0 -299 -292 -4 -293
-3 -58 -28 c-60 -30 -97 -70 -116 -126 -7 -23 -11 -145 -11 -367 l0 -334 -616
-2 -616 -3 -19 -24 c-26 -32 -24 -73 4 -99 l23 -22 616 0 616 0 6 -32 c4 -19
24 -49 51 -74 71 -69 102 -74 427 -74 l278 0 0 -55 c0 -64 -22 -115 -71 -163
-65 -64 -33 -62 -1106 -62 -960 0 -973 0 -993 -20 -26 -26 -26 -74 0 -100 20
-20 29 -20 1038 -18 l1017 3 60 32 c119 62 194 177 203 310 l5 73 227 0 c247
0 293 7 356 55 19 14 44 45 57 68 22 42 22 46 22 472 0 382 -2 433 -17 460
-25 46 -68 86 -118 109 -41 19 -67 21 -287 24 l-243 4 0 299 0 299 211 0 c132
0 226 4 250 12 51 15 114 69 142 122 22 40 22 47 25 531 2 337 0 506 -8 542
-15 71 -57 125 -121 157 -52 26 -55 26 -275 26 l-223 0 -3 203 c-3 195 -4 204
-30 259 -35 76 -104 148 -175 184 l-58 29 -1430 2 c-1352 2 -1433 2 -1482 -15z
m2873 -137 c80 -23 154 -113 154 -185 l0 -30 -1566 0 -1566 0 7 33 c19 92 74
156 157 182 67 21 2743 21 2814 0z m759 -700 l25 -24 0 -486 c0 -498 -1 -513
-39 -542 -12 -9 -150 -12 -569 -13 l-553 0 -24 25 -25 24 0 496 0 496 25 24
24 25 556 0 556 0 24 -25z m-3183 -252 c16 -15 18 -36 18 -234 l0 -217 -47 15
c-64 20 -147 12 -209 -20 -27 -14 -78 -55 -114 -92 -36 -37 -77 -73 -92 -81
-32 -17 -91 -18 -121 -3 -12 6 -96 85 -187 174 -91 89 -181 171 -200 180 -74
38 -242 26 -280 -21 -7 -7 -16 -14 -21 -14 -5 0 -9 64 -9 143 0 113 3 147 16
165 l15 22 606 0 c550 0 608 -2 625 -17z m-1033 -403 c7 0 92 -77 187 -171
199 -195 229 -213 339 -213 96 0 145 24 250 125 82 79 88 83 139 87 50 4 56 2
95 -33 l41 -37 0 -82 c0 -58 -5 -88 -16 -104 l-15 -22 -609 0 -609 0 -15 22
c-13 18 -16 52 -16 163 l0 141 58 57 c59 58 100 79 137 72 11 -3 26 -5 34 -5z
m4230 -1321 c17 -6 36 -19 41 -29 6 -11 10 -175 10 -414 l0 -395 -26 -20 c-26
-21 -35 -21 -619 -21 -584 0 -593 0 -619 21 l-26 20 0 405 0 404 33 20 c31 19
51 20 603 20 391 0 581 -3 603 -11z"/>
<path d="M2622 4715 c-35 -25 -45 -60 -27 -94 8 -16 22 -31 30 -35 9 -3 66 -6
126 -6 103 0 111 1 134 25 30 29 32 64 6 96 -18 22 -27 24 -133 27 -93 3 -117
0 -136 -13z"/>
<path d="M4022 3920 c-11 -11 -24 -35 -27 -52 -3 -18 -5 -181 -3 -361 l3 -329
29 -25 c20 -17 36 -23 52 -19 35 9 597 335 617 358 23 27 22 71 -3 93 -35 32
-604 355 -626 355 -11 0 -30 -9 -42 -20z m466 -385 c-9 -8 -322 -187 -334
-192 -12 -4 -14 25 -14 195 l0 201 175 -101 c96 -56 174 -102 173 -103z"/>
<path d="M1123 3711 c-118 -54 -147 -217 -53 -311 33 -33 94 -60 136 -60 103
0 194 92 194 194 0 58 -14 93 -53 135 -60 62 -143 78 -224 42z m126 -153 c17
-45 -39 -87 -75 -58 -17 15 -18 52 -2 68 21 21 68 15 77 -10z"/>
<path d="M3901 1944 c-27 -34 -27 -64 -1 -92 21 -22 22 -22 425 -22 l404 0 20
26 c26 34 26 54 0 88 l-20 26 -404 0 -404 0 -20 -26z"/>
<path d="M3923 1730 c-47 -19 -56 -78 -18 -115 l24 -25 396 0 396 0 24 25 c14
13 25 31 25 39 0 28 -22 64 -47 75 -29 13 -766 14 -800 1z"/>
<path d="M3905 1475 c-39 -38 -32 -86 16 -110 23 -12 93 -15 397 -15 232 0
380 4 399 10 29 11 53 45 53 76 0 8 -11 26 -25 39 l-24 25 -396 0 -396 0 -24
-25z"/>
<path d="M2595 3556 c-156 -31 -303 -108 -414 -218 -104 -103 -159 -193 -207
-338 -25 -77 -28 -98 -28 -230 0 -132 3 -153 28 -230 48 -144 104 -237 207
-339 393 -389 1050 -265 1285 245 50 107 67 191 67 324 0 134 -17 217 -68 327
-100 216 -289 379 -515 443 -97 27 -260 34 -355 16z m345 -166 c354 -119 542
-516 404 -858 l-16 -41 -29 58 c-34 67 -107 141 -172 172 l-45 22 14 34 c80
190 -22 429 -214 504 -81 32 -204 31 -287 -1 -84 -33 -183 -132 -214 -214 -39
-105 -38 -170 4 -302 6 -18 0 -25 -40 -45 -63 -32 -139 -108 -167 -169 -13
-28 -26 -50 -30 -50 -4 0 -17 30 -29 68 -30 95 -37 247 -15 346 53 237 244
430 484 491 83 21 267 13 352 -15z m-125 -241 c57 -15 137 -100 154 -162 50
-177 -110 -340 -290 -297 -67 16 -151 94 -168 155 -53 196 108 357 304 304z
m-137 -608 c83 -13 161 0 242 42 56 28 71 32 100 24 95 -24 166 -117 177 -229
l6 -64 -48 -42 c-108 -95 -227 -141 -385 -149 -167 -8 -315 39 -437 139 -50
40 -53 46 -53 89 0 119 69 224 167 252 47 14 49 14 108 -19 35 -19 86 -37 123
-43z"/>
<path d="M891 2383 c-16 -13 -31 -53 -54 -142 -32 -123 -32 -124 -69 -138 -37
-15 -37 -15 -132 41 -123 73 -135 77 -171 61 -17 -7 -85 -69 -153 -137 -101
-103 -122 -130 -122 -154 0 -19 23 -70 61 -134 l62 -104 -15 -40 -14 -40 -124
-31 c-79 -21 -128 -38 -136 -50 -21 -28 -26 -91 -22 -253 4 -187 0 -182 159
-222 169 -43 178 -64 90 -215 -36 -61 -61 -116 -61 -133 0 -23 26 -53 138
-165 102 -100 145 -137 163 -137 13 0 74 29 134 64 l110 64 36 -19 c35 -17 37
-22 65 -130 44 -169 27 -159 264 -159 l199 0 19 25 c11 14 32 74 47 135 24 99
31 112 56 125 15 8 35 15 44 15 8 0 62 -27 118 -60 57 -33 114 -60 127 -60 17
0 63 39 162 137 116 115 138 142 138 167 0 18 -23 69 -61 132 -58 99 -60 105
-49 139 6 19 16 38 22 41 6 4 60 19 121 35 159 40 152 27 152 265 0 228 4 220
-112 248 -165 41 -169 42 -182 84 -12 37 -12 39 48 141 39 66 61 114 61 133 0
26 -19 51 -122 156 -68 68 -136 130 -153 137 -36 16 -49 11 -171 -62 l-95 -56
-37 17 c-37 17 -38 18 -67 134 -43 169 -31 162 -263 162 -166 0 -192 -2 -211
-17z m328 -170 c6 -21 18 -69 27 -106 9 -38 24 -77 33 -87 21 -26 158 -83 196
-83 18 0 67 22 125 57 l94 56 78 -77 77 -77 -19 -30 c-59 -97 -100 -173 -100
-186 0 -23 66 -181 83 -197 7 -8 40 -21 73 -29 32 -8 83 -22 112 -30 l52 -15
0 -108 0 -109 -109 -27 c-61 -15 -117 -32 -125 -39 -17 -12 -75 -145 -83 -187
-4 -20 10 -51 51 -122 31 -52 56 -99 56 -104 0 -5 -33 -42 -74 -82 l-74 -74
-97 57 c-53 31 -106 56 -119 56 -12 0 -62 -17 -112 -38 -100 -43 -89 -27 -135
-200 l-21 -82 -108 0 -107 0 -28 108 c-15 59 -32 115 -39 125 -12 20 -168 87
-202 87 -12 0 -65 -25 -119 -56 l-97 -57 -74 74 c-41 40 -74 77 -74 82 0 5 25
51 56 103 43 72 55 100 51 121 -8 43 -66 177 -82 189 -9 6 -65 24 -125 39
l-110 29 0 107 c0 94 2 108 18 113 9 3 59 17 111 30 52 14 101 32 109 39 19
19 82 167 82 192 0 11 -26 66 -59 121 l-59 102 77 76 77 77 94 -55 c52 -31
102 -58 112 -62 19 -6 171 50 200 74 19 16 26 34 58 160 l22 82 109 0 108 0
10 -37z"/>
<path d="M1010 1880 c-224 -35 -418 -213 -475 -435 -20 -77 -19 -211 1 -289
53 -204 222 -369 434 -422 132 -33 305 -5 436 71 113 66 223 215 259 351 19
73 19 222 0 294 -50 187 -207 352 -390 409 -74 23 -192 32 -265 21z m195 -155
c172 -45 298 -183 327 -359 30 -183 -68 -368 -240 -453 -75 -37 -78 -38 -191
-38 -110 0 -120 2 -184 32 -173 82 -279 277 -248 460 43 256 291 421 536 358z"/>
<path d="M2609 1052 c-30 -25 -30 -75 0 -105 20 -20 31 -22 142 -22 115 0 122
1 140 24 26 32 24 73 -4 99 -21 20 -34 22 -139 22 -98 0 -119 -3 -139 -18z"/>
</g>
 </SvgIcon>
  );
}