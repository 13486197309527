import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function MarketResearchIcon({fill}) {
  return (
    <SvgIcon version="1.0" xmlns="http://www.w3.org/2000/svg"
    style={{
      width:'60%',
      height:'60%'
    }}
  viewBox="0 0 512 512"
 preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#439553" stroke="none">
<path d="M165 4606 c-27 -12 -69 -43 -91 -68 -80 -86 -75 14 -72 -1434 l3
-1289 22 -42 c28 -51 82 -105 131 -130 35 -17 88 -18 955 -21 l917 -2 0 -300
0 -300 -363 0 c-404 0 -421 -2 -495 -66 -145 -124 -115 -345 61 -441 30 -17
107 -18 1327 -18 1220 0 1297 1 1327 18 176 96 206 317 61 441 -74 64 -91 66
-495 66 l-363 0 0 300 0 300 682 0 682 0 101 -100 c110 -108 163 -140 235
-140 123 0 210 100 197 226 -3 30 -2 54 2 54 21 0 93 90 111 137 20 53 20 79
20 1328 0 1248 0 1275 -20 1327 -23 63 -85 128 -150 157 -45 20 -55 21 -2390
21 l-2345 0 -50 -24z m4763 -75 c47 -24 77 -56 97 -103 13 -33 15 -183 15
-1303 0 -1122 -2 -1270 -16 -1303 -16 -40 -61 -92 -79 -92 -6 0 -42 32 -80 70
-65 65 -68 70 -43 70 17 0 31 8 38 19 14 27 14 2445 0 2472 -11 19 -47 19
-2300 19 -2253 0 -2289 0 -2300 -19 -16 -30 -14 -2463 2 -2479 9 -9 462 -12
1978 -12 l1965 0 85 -85 85 -85 -2072 0 c-1439 0 -2080 3 -2100 11 -40 15 -82
57 -104 104 -18 38 -19 95 -19 1310 0 1214 1 1272 19 1310 20 44 51 76 96 99
27 14 269 16 2363 16 2273 0 2334 0 2370 -19z m-140 -373 l-3 -143 -2225 0
-2225 0 -3 143 -3 142 2231 0 2231 0 -3 -142z m2 -1218 l0 -990 -37 0 c-36 0
-51 13 -324 286 l-286 286 23 42 c12 22 37 84 55 136 29 85 33 110 37 235 3
118 1 154 -17 228 -81 338 -349 593 -686 652 -159 27 -315 12 -465 -46 -255
-98 -449 -318 -523 -594 -30 -111 -29 -305 2 -420 78 -293 290 -515 581 -611
79 -26 96 -28 255 -28 167 -1 172 0 265 32 52 18 113 44 136 57 l40 24 140
-140 139 -139 -1898 0 -1897 0 0 990 0 990 2230 0 2230 0 0 -990z m-1185 784
c215 -58 400 -210 495 -409 64 -132 82 -212 82 -350 -1 -343 -210 -630 -537
-741 -115 -39 -304 -46 -425 -15 -450 112 -706 585 -551 1021 38 107 83 180
166 268 127 136 279 216 460 246 56 9 250 -3 310 -20z m545 -1319 l44 -45 -89
-90 -89 -90 -47 46 -47 46 87 88 c47 48 88 88 91 89 3 0 26 -19 50 -44z m422
-422 c174 -175 322 -331 327 -346 42 -112 -47 -207 -156 -167 -15 6 -173 156
-350 333 l-323 322 87 87 c48 49 90 88 93 88 3 0 148 -143 322 -317z m-1567
-663 l0 -295 -445 0 -445 0 -3 285 c-1 157 0 290 3 297 3 11 96 13 447 11
l443 -3 0 -295z m853 -401 c126 -61 137 -239 20 -316 l-42 -28 -1276 0 -1276
0 -42 28 c-125 83 -103 269 39 326 15 6 517 10 1278 10 l1255 1 44 -21z"/>
<path d="M467 4184 c-12 -12 -8 -51 7 -63 22 -18 117 -10 130 12 20 32 -4 52
-70 55 -33 2 -63 0 -67 -4z"/>
<path d="M732 4178 c-33 -33 2 -68 68 -68 66 0 101 35 68 68 -16 16 -120 16
-136 0z"/>
<path d="M1002 4178 c-15 -15 -16 -40 0 -56 7 -7 35 -12 64 -12 57 0 78 15 72
52 -3 21 -9 23 -63 26 -38 2 -65 -2 -73 -10z"/>
<path d="M2001 3596 c-8 -9 -10 -181 -9 -622 l3 -609 150 -3 c132 -2 151 -1
162 15 10 13 13 149 13 609 0 525 -2 594 -16 608 -21 22 -285 24 -303 2z m239
-611 l0 -545 -85 0 -85 0 0 545 0 545 85 0 85 0 0 -545z"/>
<path d="M981 3206 c-8 -9 -10 -130 -9 -427 l3 -414 152 -3 c144 -2 153 -1
163 18 7 13 10 147 8 420 -3 343 -5 402 -18 410 -25 16 -285 13 -299 -4z m237
-418 l2 -348 -85 0 -85 0 0 350 0 351 83 -3 82 -3 3 -347z"/>
<path d="M1485 3137 c-3 -6 -4 -183 -3 -392 l3 -380 150 -3 c132 -2 151 -1
162 15 17 22 19 724 3 754 -10 17 -23 19 -161 19 -111 0 -151 -3 -154 -13z
m245 -382 l0 -315 -85 0 -85 0 0 315 0 315 85 0 85 0 0 -315z"/>
<path d="M465 2998 c-3 -7 -4 -153 -3 -323 l3 -310 155 0 155 0 0 320 0 320
-153 3 c-117 2 -154 0 -157 -10z m235 -313 l0 -245 -80 0 -80 0 0 245 0 245
80 0 80 0 0 -245z"/>
<path d="M478 2279 c-25 -14 -27 -184 -2 -193 9 -3 422 -6 919 -6 1039 0 925
-13 925 109 0 61 -3 72 -22 85 -20 14 -123 16 -913 16 -586 -1 -896 -4 -907
-11z m1760 -96 l3 -23 -850 0 -851 0 0 25 0 25 848 -2 847 -3 3 -22z"/>
<path d="M3234 3600 c-211 -55 -379 -212 -451 -423 -34 -99 -42 -246 -19 -351
51 -228 227 -417 451 -485 362 -111 747 115 829 486 33 147 14 292 -55 433
-34 68 -59 102 -128 171 -96 95 -185 146 -301 173 -89 21 -238 19 -326 -4z
m311 -75 c100 -25 184 -74 262 -150 307 -302 183 -821 -229 -956 -100 -33
-242 -33 -345 -1 -86 27 -206 104 -263 170 -49 57 -114 187 -129 260 -25 122
-2 286 56 395 15 29 61 86 103 128 78 79 158 127 256 154 67 18 217 18 289 0z"/>
<path d="M3291 3375 c-81 -23 -145 -62 -206 -126 -208 -218 -141 -569 132
-700 59 -29 81 -34 165 -37 80 -4 107 -1 162 18 131 43 244 159 283 289 65
216 -55 462 -264 541 -76 29 -199 35 -272 15z m-5 -105 c-19 -17 -40 -47 -47
-67 -19 -58 -6 -163 27 -230 16 -32 31 -61 32 -65 2 -5 -8 -8 -23 -8 -44 0
-122 -49 -154 -97 l-29 -43 -23 53 c-34 78 -33 201 3 279 36 77 98 141 171
178 76 38 87 38 43 0z m298 -8 c127 -69 204 -233 176 -376 -11 -57 -49 -146
-50 -119 0 21 -71 93 -111 114 -21 10 -50 19 -64 19 -14 0 -25 2 -25 4 0 2 14
34 31 70 57 123 48 241 -24 302 -30 25 -30 26 -7 19 14 -4 47 -19 74 -33z
m-114 -50 c31 -25 37 -95 15 -165 -18 -57 -56 -107 -81 -107 -43 0 -94 105
-94 192 0 66 30 98 93 98 28 0 53 -7 67 -18z m-20 -350 c0 -9 -42 -62 -49 -62
-6 0 -41 52 -41 62 0 1 20 3 45 3 25 0 45 -2 45 -3z m-114 -112 c63 -94 75
-93 143 8 23 34 47 62 54 62 20 0 75 -38 91 -62 8 -13 18 -35 21 -49 6 -22 1
-31 -29 -54 -115 -87 -307 -88 -422 0 -19 14 -34 34 -34 43 0 42 77 122 117
122 7 0 34 -31 59 -70z"/>
</g>
 </SvgIcon>
  );
}