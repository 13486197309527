import React from 'react';
import { Typography, Box, Paper, Grid} from '@mui/material';
import { styled, keyframes } from '@mui/system';

const ContentImage = styled('img')({
    width: '100%',
    borderRadius: '10px',
    objectFit: 'cover',
});

const floating = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
`;

export default function AboutUs() {
    return (
        <Grid container sx={{ position: 'relative', overflow: 'hidden', minHeight: '100vh', padding: { xs: 4, md: 6 } }}>
            {/* Background Gradient and Pattern */}
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'linear-gradient(135deg, #e3f2fd 0%, #f9fbe7 100%)',
                zIndex: 0,
                opacity: 0.8,
                backgroundImage: 'url(/pattern.png)',
                backgroundRepeat: 'repeat',
                backgroundSize: '200px 200px',
            }} />
            
            {/* Decorative Shapes */}
            <Box sx={{
                position: 'absolute',
                top: '10%',
                left: '10%',
                width: { xs: '60px', md: '100px' },
                height: { xs: '60px', md: '100px' },
                backgroundColor: '#8bc34a',
                borderRadius: '50%',
                opacity: 0.3,
                animation: `${floating} 6s infinite ease-in-out`,
                zIndex: 1,
            }} />
            <Box sx={{
                position: 'absolute',
                bottom: '10%',
                right: '10%',
                width: { xs: '90px', md: '150px' },
                height: { xs: '90px', md: '150px' },
                backgroundColor: '#ffc107',
                borderRadius: '50%',
                opacity: 0.3,
                animation: `${floating} 8s infinite ease-in-out`,
                zIndex: 1,
            }} />
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '30%',
                width: { xs: '50px', md: '80px' },
                height: { xs: '50px', md: '80px' },
                backgroundColor: '#03a9f4',
                borderRadius: '50%',
                opacity: 0.3,
                animation: `${floating} 10s infinite ease-in-out`,
                zIndex: 1,
            }} />
            <Box sx={{
                position: 'absolute',
                bottom: '30%',
                left: '5%',
                width: { xs: '70px', md: '120px' },
                height: { xs: '70px', md: '120px' },
                backgroundColor: '#e91e63',
                borderRadius: '50%',
                opacity: 0.3,
                animation: `${floating} 12s infinite ease-in-out`,
                zIndex: 1,
            }} />
            <Box sx={{
                position: 'absolute',
                top: '70%',
                right: '25%',
                width: { xs: '40px', md: '60px' },
                height: { xs: '40px', md: '60px' },
                backgroundColor: '#9c27b0',
                borderRadius: '50%',
                opacity: 0.3,
                animation: `${floating} 14s infinite ease-in-out`,
                zIndex: 1,
            }} />

            <Grid container item xs={12} sx={{ height: 'auto', zIndex: 2, mb: 4, textAlign: 'center' }} justifyContent="center">
                <Grid item xs={12} md={8} lg={6}>
                    <Typography fontSize={{ xs: 40, md: 80 }}
                        fontWeight={700}
                        lineHeight={1}
                        color={"#439553"}
                        sx={{ mt: 2 }}>
                        About us
                    </Typography>
                </Grid>
                <Grid item xs={12} md={10} lg={8} sx={{
                    background: '#439553',
                    borderRadius: 20,
                    mt: 2,
                    p: 2,
                    mx: 1,
                }}>
                    <Typography variant="h6" color="white"  textAlign='center'>
                        We continuously strive to introduce innovative solutions and drive meaningful change.
                    </Typography>
                </Grid>
            </Grid>

            <Grid container item xs={12} md={10} lg={8} spacing={4} sx={{ zIndex: 2 }} justifyContent="center">
                <Grid item xs={12} md={6} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <ContentImage src="/black2.jpeg" alt="Team discussion" />
                </Grid>

                <Grid item xs={12} md={6} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Box width={'100%'}>
                        <Paper elevation={12} sx={{
                            padding: { xs: 2, md: 4 },
                            background: '#439553',
                            borderRadius: '10px',
                        }}>
                            <Typography variant="h4" component="h2" gutterBottom  color="white">
                                We're a full-service marketing and media solutions firm.
                            </Typography>
                            <Typography variant="body1"  color="white" paragraph>
                                Nestled in the heart of Johannesburg, we specialize in bespoke marketing and media solutions on a national basis.
                                We bring a fresh, dynamic, pro-active as well as holistic approach to all our projects ensuring maximum client satisfaction.
                            </Typography>
                            <Typography variant="body1"  color="white" paragraph>
                                We pride ourselves in our continuous efforts of bringing innovation and transformation to the table.
                            </Typography>
                            <Typography variant="body1"  color="white" paragraph>
                                Driven by our competitive edge, we are a force to be reckoned with!
                            </Typography>
                            {/* <Button component={Link} to={'/how-we-do-it'} sx={{ background: '#F19C1B', mt: 2, '&:hover': { backgroundColor: '#ff9800' } }}>
                                <Typography variant="body1" color="white">
                                    Discover how we do it.
                                </Typography>
                            </Button> */}
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};
