import React from 'react'
import Footer from '../Components/Footer';
import Home from './Home';
import AboutUs from './AboutUs';
import HowWeDoIt from './HowWeDoIt'
import WhoWeAre from './WhoWeAre';
import Media from './Media';
import ContactUs from './ContactUs';

export default function DefaultPage() {
  return (
    <>
        <Home/>
        <AboutUs />
        <WhoWeAre />
        <HowWeDoIt />
        <Media />
        <ContactUs/>
        <Footer />
    </>
  )
}
