import React from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GroupIcon from '@mui/icons-material/Group';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';

export default function ContactUs() {
    return (
        <Grid container xs={12} justifyContent="center" pb={2} sx={{ background: 'linear-gradient(135deg, #2f3c42 45%, #439553 100%)' }} rowGap={2}>
            <Grid container item xs={12} alignItems="center" justifyContent="space-between" sx={{ zIndex: 1 }}>
                <Grid item xs={12} md={6} display="flex" alignItems="center" justifyContent="center">
                    <Typography fontSize={{ xs: 40, md: 80 }} fontWeight={700} color="#F19C1B" sx={{ textAlign: 'center' }}>
                        Contact Us
                    </Typography>
                </Grid>
                <Grid item xs={12}  md={6} display="flex" pt={2} >
                    <Typography variant="h5" fontSize={{ xs: 15, md: 25}} color="white" sx={{ textAlign: { xs: 'center', md: 'left' }, margin: { xs: 2, md: 0 }, width: { xs: '100%', md: '80%' }, textWrap: 'nowrap', textDecoration: 'underLine 10px #F19C1B ' }}>
                        Hello and welcome, we’ve been waiting for you...
                    </Typography>
                </Grid>
            </Grid>
            <Grid display={'flex'} item xs={12} md={6} justifyContent={'center'}>
                <Paper elevation={3} sx={{ padding: '20px', background: '#439553', width: '80%', background: 'transparent' }} >
                    <LocationOnIcon fontSize="large" style={{ color: '#F19C1B' }} />
                    <Typography variant="h5" style={{ color: '#F19C1B' }}>Office Location</Typography>
                    <Typography variant="body1" color={"white"}>
                        Thornhill Office Park, 94 Bekker Road,<br />
                        Vorna Valley, Midrand, 1686
                    </Typography>
                </Paper>
            </Grid>
            <Grid display={'flex'} item xs={12} md={6} justifyContent={'center'}>
                <Paper elevation={3} sx={{ padding: '20px', background: '#439553', width: '80%', background: 'transparent' }}>
                    <EmailIcon fontSize="large" style={{ color: '#F19C1B' }} />
                    <Typography variant="h5" style={{ color: '#F19C1B' }}>Send a Message</Typography>
                    <Typography variant="body1" color={"white"}>
                        <Typography variant="body1" color={"white"}>
                            admin@govmark.co.za
                        </Typography>
                    </Typography>
                </Paper>
            </Grid>
            <Grid display={'flex'} item xs={12} md={6} justifyContent={'center'}>
                <Paper elevation={3} sx={{ padding: '20px', background: '#439553', width: '80%', background: 'transparent' }}>
                    <PhoneIcon fontSize="large" style={{ color: '#F19C1B' }} />
                    <Typography variant="h5" style={{ color: '#F19C1B' }}>Let's Talk</Typography>
                    <Typography variant="body1" color={"white"}>
                        Phone: 010 979 0008
                    </Typography>
                </Paper>
            </Grid>
            <Grid display="flex" item xs={12} md={6} justifyContent="center">
                <Paper elevation={3} sx={{ padding: '20px', background: 'transparent', width: '80%' }}>
                    <GroupIcon fontSize="large" style={{ color: '#F19C1B' }} />
                    <Typography variant="h5" style={{ color: '#F19C1B' }}>Collaborate</Typography>
                    <Typography variant="body1" color="white">
                        We welcome collaboration opportunities and look forward to working together to achieve great results. Contact us today to start the conversation!
                    </Typography>
                    <Typography variant="body1" color="white">
                        <InstagramIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> Instagram: Govmark_<br />
                        <TwitterIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> Twitter: Govmark_SA<br />
                        <FacebookIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> Facebook: GovMark
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
    );
}
