import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Typography, Box, Grid } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/material/styles';
import ReputationalManagementIcon from '../Icons/ReputationalManagementIcon.js';
import ConceptDevelopmentIcon from '../Icons/ConceptDevelopmentIcon.js';
import MediaStartIcon from '../Icons/MediaStratIcon.js';
import MediaPlacementIcon from '../Icons/MediaPlacementIcon.js';
import DigitalStrategyIcon from '../Icons/DigitalStategy.js';
import EventsIcon from '../Icons/EventsIcon.js';
import BrandIdentityIcon from '../Icons/BrandIdentityIcon.js';
import CopywritingIcon from '../Icons/CopyWrittingIcon.js';
import ReportingIcon from '../Icons/ReportingIcon.js';
import MarketResearchIcon from '../Icons/MarkerResearchIcon.js';
import BrandComsIcon from '../Icons/BrandComsIcon.js';
import ContentGenerationIcon from '../Icons/ContentGenerationIcon.js';

// Define styled components with responsive design
const StyledAccordion = styled(Accordion)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  borderRadius: 10,
  minHeight:'12vh',
  background: '#F19C1B',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    backgroundColor: '#e08a17',
  },
}));

const AccordionSummaryContainer = styled(Box)(({ theme }) => ({
  minWidth: '45%',
  alignItems: 'center',
  borderTopLeftRadius: 10,
  borderBottomLeftRadius: 10,
  padding: theme.spacing(1),
}));

const AccordionDetailsContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderLeft: `1px solid ${theme.palette.divider}`,
  borderTopRightRadius: 10,
  borderBottomRightRadius: 10,
}));

export default function WhoWeAre() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const data1 = [
    {
      field: ' Brand and Marketing Communications',
      description: 'With our specialist brand strategy offer, we help you define your key strategic brand positioning, long-term promise, personality, values, brand architecture and visual identity. Also helping you navigate major transformation or change in your brand.',
      icon: <BrandComsIcon />
    },
    {
      field: 'PR and Reputational Management',
      description: 'We work with you to create an integrated PR and Reputation Plan. We audit brand perceptions and engage to build a positive and long-lasting reputation.',
      icon: <ReputationalManagementIcon />
    },
    {
      field: 'Creative and Concept Development',
      description: 'We work tirelessly to brainstorm and exhaust all strategies to deliver original, fresh and relevant ideas for your campaign execution.',
      icon: <ConceptDevelopmentIcon />
    },
    {
      field: 'Content Generation and Production',
      description: 'We promise to deliver authentic, relatable content resonates with audiences, particularly in an era where transparency is so highly valued. We do this while offering unmatched storytelling, innovation, production quality and strategic planning. With access to state-of-the-art production equipment, we will deliver top-notch final products for any campaign.',
      icon: <ContentGenerationIcon />
    },
    {
      field: 'Media Strategy and Planning',
      description: 'With many choices today that continue to change, through research we define the right mix of media channels for your campaign objectives to help build brand affinity.',
      icon: <MediaStartIcon />
    },
    {
      field: 'Media Placement',
      description: 'Let us leverage our relationships with the media and our understanding of the media landscape to secure you the best possible media placement deals for your advertisements and campaigns. Value for your pocket!',
      icon: <MediaPlacementIcon />
    }
  ];

  const data2 = [
    {
      field: 'Digital Strategy',
      description: "We work with you to co-create your digital footprint. Broadly speaking, digital marketing is often linked exclusively to social media or paid advertising, but we apply it to all forms of digital brand and online presence. Full social media management, SEO and PPC requires specialists, and we'll partner with them to ensure that the brand and core messaging is relevant and accurate to your digital marketing goals.",
      icon: <DigitalStrategyIcon />
    },
    {
      field: 'Events Management',
      description: 'Executing and managing an event from an incubation to the final product can be a time consuming, stressful, and expensive exercise if you go at it yourself. Let our experienced team take the stress and worry away by managing your next event.',
      icon: <EventsIcon />
    },
    {
      field: 'Graphic Design and Corporate Identity',
      description: 'Our design studio capabilities are a hybrid of creativity, where briefs are brought to life. From creating corporate identities, to artwork that is unique to your campaign, our team of extremely talented graphic designers never disappoint. We will transform your brand story by utilizing four key CI components (design, language, culture and behavior).',
      icon: <BrandIdentityIcon />
    },
    {
      field: 'Copywriting',
      description: 'At a loss for words? Perhaps you need assistance delivering the right message? Our highly skillful and in-house copywriters will bring your campaign to life and wax lyrical in our bid to deliver your messaging in the best possible way.',
      icon: <CopywritingIcon />
    },
    {
      field: 'Market Research and Intelligence',
      description: "We provide market research, industry expertise & data insights to help you make better business decisions with speed, confidence and impact. We are data and analytics obsessed, we will analyze trends and opportunities to maximize your output and help your brand stay ahead of it’s competitive.",
      icon: <MarketResearchIcon />
    },
    {
      field: 'Reporting',
      description: 'We provide a complete final report tailored to your individual needs. A complete summary showing actual deliveries of all media. We look at performance vs plan, how effective was it? What would we change or add to the next campaign?',
      icon: <ReportingIcon />
    }
  ];

  return (
    <Grid container sx={{
      background: 'linear-gradient(136deg, #2f3c42 40%, #439553 100%)',
      backgroundBlendMode: 'overlay',
      padding: { xs: 2, md: 5 },
      position: 'relative',
      minHeight: '100vh',
      color: 'white',
    }} spacing={4}>
      {/* Overlay for better readability */}
      <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 0,
      }} />

      {/* Title Section */}
      <Grid container item xs={12} alignItems="center" justifyContent="space-between" sx={{ zIndex: 1 }}>
        <Grid item xs={12} md={6} display="flex" alignItems="center" justifyContent="center">
          <Typography fontSize={{ xs: 40, md: 80 }} fontWeight={700} color="#fff" sx={{ textAlign: 'center' }}>
            Expertise
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} display="flex" alignItems="center">
          <Typography variant="h6" color="inherit" sx={{ textAlign: { xs: 'center', md: 'left' }, margin: { xs: 2, md: 0 }, width: { xs: '100%', md: '80%' } }}>
            We continuously strive to introduce innovative solutions and drive meaningful change.
          </Typography>
        </Grid>
      </Grid>

      {/* Accordion Section 1 */}
      <Grid container item xs={12} spacing={2} sx={{ zIndex: 1 }}>
        {
          data1.map(({ field, description, icon }, index) => (
            <Grid container item xs={12} md={6} key={index} sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid item xs={1} display="flex" justifyContent="center" alignItems="center" pt={1}>
                {icon}
              </Grid>
              <Grid item xs={11}>
                <StyledAccordion expanded={expanded === `panel-${index}`} onChange={handleChange(`panel-${index}`)}>
                  <AccordionSummaryContainer>
                    <AccordionSummary expandIcon={<ChevronRightIcon />} aria-controls={`panel-${field}bh-content`} id={`panel-${field}bh-header`}>
                      <Typography sx={{ width: '100%', flexShrink: 0, fontWeight: 600 }}>
                        {field}
                      </Typography>
                    </AccordionSummary>
                  </AccordionSummaryContainer>
                  <AccordionDetailsContainer>
                    <AccordionDetails>
                      <Typography variant="body2" color="textSecondary">
                        {description}
                      </Typography>
                    </AccordionDetails>
                  </AccordionDetailsContainer>
                </StyledAccordion>
              </Grid>
            </Grid>
          ))
        }
      </Grid>

      {/* Accordion Section 2 */}
      <Grid container item xs={12} spacing={2} sx={{ zIndex: 1 }}>
        {
          data2.map(({ field, description, icon }, index) => (
            <Grid container item xs={12} md={6} key={index} sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid item xs={1} display="flex" justifyContent="center" alignItems="center" pt={1}>
                {icon}
              </Grid>
              <Grid item xs={11}>
                <StyledAccordion expanded={expanded === `panel-${index + data1.length}`} onChange={handleChange(`panel-${index + data1.length}`)}>
                  <AccordionSummaryContainer>
                    <AccordionSummary expandIcon={<ChevronRightIcon />} aria-controls={`panel-${field}bh-content`} id={`panel-${field}bh-header`}>
                      <Typography sx={{ width: '100%', flexShrink: 0, fontWeight: 600 }}>
                        {field}
                      </Typography>
                    </AccordionSummary>
                  </AccordionSummaryContainer>
                  <AccordionDetailsContainer>
                    <AccordionDetails>
                      <Typography variant="body2" color="textSecondary">
                        {description}
                      </Typography>
                    </AccordionDetails>
                  </AccordionDetailsContainer>
                </StyledAccordion>
              </Grid>
            </Grid>
          ))
        }
      </Grid>
    </Grid>
  );
}
