
import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function DigitalStrategyIcon({fill}) {
  return (
    <SvgIcon version="1.0" xmlns="http://www.w3.org/2000/svg"
    style={{
      width:'60%',
      height:'60%'
    }}
  viewBox="0 0 512 512"
 preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#439553" stroke="none">
<path d="M1678 4899 c-166 -17 -324 -94 -448 -219 -109 -109 -174 -230 -205
-378 -19 -87 -19 -229 1 -317 8 -38 17 -82 21 -97 l5 -28 -303 0 c-274 0 -308
-2 -345 -19 -58 -27 -111 -77 -142 -135 l-27 -51 0 -1325 c0 -1253 1 -1327 18
-1363 27 -58 92 -122 150 -148 l52 -24 758 -3 757 -3 0 -229 0 -230 -173 0
c-156 0 -176 -2 -190 -18 -18 -19 -22 -49 -9 -68 22 -33 45 -34 969 -34 1019
0 963 -4 963 64 0 54 -7 56 -202 56 l-178 0 0 230 0 229 758 3 757 3 50 23
c63 29 109 73 143 137 l27 50 0 1325 0 1325 -27 51 c-31 58 -84 108 -142 135
-37 17 -71 19 -345 19 l-303 0 6 23 c46 158 48 366 3 502 -82 248 -294 442
-547 500 -337 76 -684 -86 -840 -394 -55 -107 -71 -174 -77 -311 -5 -122 -3
-139 34 -297 5 -23 4 -23 -86 -23 -50 0 -91 2 -91 3 0 2 10 39 21 83 30 110
30 288 1 397 -57 209 -204 388 -397 482 -139 67 -261 89 -417 74z m281 -164
c191 -61 344 -214 407 -409 31 -95 38 -244 15 -336 -57 -235 -249 -423 -487
-476 -213 -47 -434 23 -591 190 -292 306 -200 808 181 997 150 74 312 85 475
34z m1602 -2 c155 -51 284 -160 357 -300 66 -129 89 -250 71 -381 -46 -331
-333 -569 -664 -549 -258 15 -470 175 -563 425 -36 98 -42 248 -13 361 59 235
266 428 502 470 87 16 218 5 310 -26z m-2413 -1035 c38 -55 125 -142 180 -180
60 -42 176 -95 252 -115 88 -24 283 -24 362 0 31 9 58 17 61 17 7 0 427 -526
427 -534 0 -3 -160 -5 -357 -4 l-356 3 -18 52 c-38 113 -91 195 -184 288 -103
103 -168 145 -292 187 -106 37 -259 48 -369 28 -177 -32 -343 -131 -454 -272
l-37 -46 -7 51 c-3 29 -6 138 -6 242 0 178 2 194 23 235 12 25 37 55 56 67 34
23 38 23 362 23 l328 0 29 -42z m1600 0 c39 -56 146 -158 205 -196 l47 -29
-216 -272 c-119 -149 -220 -271 -224 -271 -4 0 -105 121 -224 269 l-215 270
36 22 c69 43 151 118 200 184 l48 65 157 0 157 0 29 -42z m1942 20 c18 -13 43
-36 54 -51 20 -27 21 -42 23 -290 l3 -262 -32 40 c-185 233 -467 337 -756 280
-161 -33 -350 -153 -445 -283 -42 -56 -100 -170 -118 -229 l-12 -43 -354 0
c-228 0 -353 4 -353 10 0 5 92 126 203 267 147 186 209 258 223 256 10 0 57
-9 104 -18 98 -20 218 -16 321 10 154 39 318 148 407 271 l47 64 326 0 c322 0
326 0 359 -22z m-3546 -404 c204 -53 376 -215 443 -418 22 -68 27 -99 27 -196
0 -103 -3 -124 -30 -200 -51 -145 -148 -265 -276 -343 -178 -107 -419 -115
-610 -20 -85 43 -203 151 -252 232 -219 359 -36 824 369 942 79 23 245 25 329
3z m3150 0 c149 -39 308 -156 384 -286 250 -423 -51 -959 -538 -958 -180 0
-320 58 -446 184 -134 133 -188 264 -188 451 0 94 4 122 28 190 72 207 226
356 431 416 79 23 245 25 329 3z m-1794 -614 l0 -60 -244 0 -245 0 -20 -26
c-20 -26 -21 -37 -21 -426 l0 -399 25 -24 24 -25 241 0 240 0 0 -51 c0 -43 5
-55 29 -80 53 -53 77 -44 239 88 l54 43 139 0 c135 0 141 1 164 25 l25 24 0
399 c0 389 -1 400 -21 426 l-20 26 -245 0 -244 0 0 60 0 60 383 0 382 0 1 -83
c3 -174 73 -350 189 -474 78 -85 135 -128 232 -177 184 -92 419 -101 615 -21
97 39 182 101 269 193 l79 85 0 -387 0 -386 -2210 0 -2210 0 0 386 0 385 80
-84 c159 -169 337 -247 558 -247 364 0 674 264 737 626 8 48 15 109 15 135 l0
49 380 0 380 0 0 -60z m528 -507 l-3 -328 -124 -3 -124 -3 -78 -65 -79 -65 0
49 c0 82 2 81 -281 84 l-244 3 -3 328 -2 327 470 0 470 0 -2 -327z m1742 -973
c0 -186 -6 -215 -56 -262 -16 -14 -47 -31 -69 -37 -55 -15 -4114 -15 -4168 0
-51 14 -104 63 -117 107 -5 20 -10 110 -10 200 l0 162 2210 0 2210 0 0 -170z
m-1740 -660 l0 -230 -467 2 -468 3 -3 228 -2 227 470 0 470 0 0 -230z"/>
<path d="M1508 4521 c-46 -15 -83 -42 -108 -79 -25 -35 -25 -38 -28 -295 -3
-248 -2 -261 19 -304 13 -27 37 -55 62 -71 l40 -27 246 -3 c264 -4 299 1 351
44 58 50 60 58 60 343 0 269 -4 297 -43 339 -50 54 -64 57 -327 59 -135 1
-257 -2 -272 -6z m500 -127 c21 -14 22 -22 22 -150 l0 -136 -31 26 c-43 36
-68 33 -135 -19 l-58 -45 -71 70 c-87 86 -100 88 -180 20 l-60 -51 -3 118 c-1
65 0 127 2 139 10 38 41 43 271 43 184 1 224 -2 243 -15z m-212 -464 c18 0 49
17 86 46 64 50 60 50 121 -5 34 -31 36 -73 5 -95 -19 -13 -60 -16 -250 -16
-215 0 -229 1 -248 20 -36 36 -26 62 53 129 l72 61 67 -70 c51 -53 74 -70 94
-70z"/>
<path d="M3164 4541 c-55 -25 -66 -44 -116 -190 l-43 -126 0 -222 0 -223 38
-37 37 -38 263 -3 c256 -2 263 -2 301 20 25 14 48 40 63 67 21 40 23 57 23
180 0 122 -2 141 -22 181 -42 80 -90 99 -255 100 -57 0 -103 2 -103 5 0 2 9
28 21 57 25 63 22 136 -8 176 -23 32 -97 72 -133 72 -14 0 -44 -9 -66 -19z
m103 -117 c3 -8 -6 -55 -21 -103 -25 -82 -26 -91 -13 -123 26 -62 45 -68 197
-68 185 0 180 4 180 -151 0 -66 -5 -129 -10 -140 -10 -18 -24 -19 -245 -19
l-235 0 0 185 0 185 36 113 c20 61 41 118 46 125 15 17 57 15 65 -4z"/>
<path d="M674 3069 c-41 -12 -101 -79 -109 -121 -4 -18 -5 -112 -3 -209 l3
-176 29 -37 c36 -47 79 -66 153 -66 54 0 64 -4 157 -65 84 -56 105 -65 143
-65 54 0 98 37 108 93 l7 37 68 0 c82 0 124 24 158 90 20 41 22 58 22 210 0
184 -7 224 -47 263 -57 54 -78 57 -380 56 -153 0 -292 -5 -309 -10z m594 -125
c21 -15 22 -21 22 -170 0 -88 -4 -164 -10 -175 -9 -17 -22 -19 -103 -19 -117
0 -135 -10 -139 -80 l-3 -49 -99 64 c-96 64 -100 65 -165 65 -39 0 -72 5 -79
12 -8 8 -12 61 -12 172 0 134 3 162 17 178 15 17 37 18 282 18 225 0 270 -2
289 -16z"/>
<path d="M3780 2992 c-63 -31 -70 -60 -70 -292 0 -313 -3 -310 313 -310 135 0
216 4 230 11 38 20 66 50 72 80 l6 29 59 -30 c72 -36 100 -37 137 -6 l28 24 3
196 3 196 -30 30 c-38 38 -70 38 -140 0 -30 -17 -56 -30 -58 -30 -1 0 -3 8 -3
19 0 10 -9 30 -21 45 -41 52 -61 56 -286 56 -178 0 -213 -3 -243 -18z m430
-292 l0 -190 -190 0 -190 0 0 190 0 190 190 0 190 0 0 -190z m230 1 l0 -109
-55 30 c-55 29 -55 30 -55 78 0 48 1 49 53 79 28 17 53 31 55 31 1 0 2 -49 2
-109z"/>
<path d="M2277 2352 c-22 -24 -21 -65 1 -85 27 -24 123 -23 145 1 24 26 21 68
-5 86 -32 23 -120 21 -141 -2z"/>
<path d="M2520 2350 c-25 -25 -26 -54 0 -80 18 -18 33 -20 163 -20 78 0 147 4
153 8 6 4 17 18 24 31 10 20 9 27 -8 50 -19 26 -21 26 -165 29 -136 2 -148 1
-167 -18z"/>
<path d="M2270 2090 c-6 -12 -10 -33 -8 -48 5 -45 36 -53 191 -50 124 3 140 5
153 22 18 26 18 61 -2 80 -13 13 -43 16 -169 16 -145 0 -155 -1 -165 -20z"/>
<path d="M2696 2094 c-20 -19 -21 -54 -3 -78 17 -24 102 -32 137 -14 28 15 37
55 20 88 -9 17 -20 20 -75 20 -44 0 -68 -5 -79 -16z"/>
<path d="M2350 1199 c-29 -12 -40 -26 -40 -56 0 -50 15 -53 250 -53 235 0 250
3 250 53 0 30 -11 44 -43 56 -36 14 -383 13 -417 0z"/>
</g>
 </SvgIcon>
  );
}

