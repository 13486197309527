import React, { useState } from 'react';
import { Typography, Button, Avatar, Grid, Menu, MenuItem, useMediaQuery, IconButton } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import logo from '../assets/logo.png';

const Header = () => {
  const combinedArray = [
    { tabSection: "Home", route: "/" },
    { tabSection: "About Us", route: "about-us" },
    { tabSection: "Who we are", route: "who-we-are" },
    { tabSection: "How we do it", route: "how-we-do-it" },
    { tabSection: "Strategic Media Partners", route: "strategic-media-partners" },
    { tabSection: "Contact", route: "contact" }
  ];

  // State for the dropdown menu
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Use theme to check screen size
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // Define the screen size threshold

  return (
    <Grid container alignItems="center" spacing={2} wrap="nowrap">
      <Grid item xs>
        <Avatar alt="Govmark" src={logo} sx={{ width: { xs: 50, md: 100 }, height: { xs: 50, md: 100 } }} />
      </Grid>
      <Grid item xs={12} container spacing={1} justifyContent={isSmallScreen?'flex-end':'flex-start'} alignItems="center">
        {isSmallScreen ? (
          <Grid item xs='auto' sx={{background:'transparent'}}>
            <IconButton
              onClick={handleClick}
              sx={{
                '&:hover': { backgroundColor: '#4a643f' },
                padding: { xs: '4px', md: '8px' }, // Adjust padding if needed
              }}
            >
              <MenuIcon sx={{ color: '#439553', fontSize: { xs: '1.5rem', md: '2rem' } }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={{'& .MuiPaper-root':{
                background:'#2f3c42'
              }}}
              MenuListProps={{
                'aria-labelledby': 'menu-button',
              }}
            >
              {combinedArray.map((section, index) => (
                <MenuItem 
                  key={index} 
                  onClick={handleClose}
                  component={Link}
                  to={section.route}
               
                >
                  <Typography color='#439553' fontWeight={400} fontSize={{ xs: '0.75rem', md: '1rem' }}>
                  {section.tabSection}
                </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        ) : (
          combinedArray.map((section, index) => (
            <Grid item xs='auto'key={index}>
              <Button
                variant="text"
                sx={{
                  '&:hover': { backgroundColor: '#4a643f' },
                  padding: { xs: '4px 8px', md: '8px 16px' },
                }}
                component={Link}
                to={section.route}
              >
                <Typography color='#439553' fontWeight={400} fontSize={{ xs: '0.75rem', md: '1rem' }}>
                  {section.tabSection}
                </Typography>
              </Button>
            </Grid>
          ))
        )}
      </Grid>
    </Grid>
  );
};

export default Header;
