import React from 'react';
import { Box, Typography } from '@mui/material';

const TypographySection = () => (
    <>
  <Box position="relative">
  <Typography
      fontSize={{ xs: 40, sm: 60, md: 80, lg: 115 , xl:145}}
      fontWeight={700}
      lineHeight={1}
    >
      Marketing and Media Solutions
    </Typography>
    <Typography
      fontSize={{ xs: 40, sm: 60, md: 80, lg: 115, xl:145 }}
      fontWeight={700}
      lineHeight={1}
      position="absolute"
      top={0}
      left={0}
      color="transparent"
      sx={{
        WebkitTextStroke: '3px #439553',
        zIndex: 2,
        cursor: 'pointer'
      }}
    >
      Marketing and Media Solutions
    </Typography>
    <Typography  fontSize={{ xs: 5, sm: 10, md: 15, lg: 20, xl:25 }} color={'white'} width={'40%'} >
Fully fledged and independent 100% black woman-owned marketing and media solutions company.
    </Typography>
  </Box>
  
  </>
);

export default TypographySection;
