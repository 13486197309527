import React from 'react';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import { Typography, Grid, Paper, Box } from '@mui/material';

const timelineData = [
    {
        title: 'Innovation',
        content: `Infuse creativity and originality in media and marketing strategies.`,
    },
    {
        title: 'Integrity',
        content: `Ensure transparency, honesty, and ethical conduct in all executions.`,
    },
    {
        title: 'Collaboration',
        content: `Foster partnerships and teamwork to achieve common goals that further enhance government engagements.`,
    },
    {
        title: 'Excellence',
        content: `Strive for exceptional quality and performance in every aspect of event planning, media, and marketing endeavors.`,
    },
    {
        title: 'Impact',
        content: `Seek to make a meaningful and positive difference in government engagements.`,
    },
];

export default function HowWeDoIt() {

    return (
        <Grid container xs alignItems="center" sx={{
            background: 'linear-gradient(135deg, #e0f7fa 10%, #e0f2f1 100%)',
            padding: '2rem 0',
            position: 'relative',
            overflow: 'hidden'
        }}>
            <Box sx={{
                position: 'absolute',
                top: '10%',
                left: '10%',
                width: '100px',
                height: '100px',
                backgroundColor: '#439553',
                borderRadius: '50%',
                opacity: 0.2,
                zIndex: 0
            }} />
            <Box sx={{
                position: 'absolute',
                bottom: '15%',
                right: '15%',
                width: '150px',
                height: '150px',
                backgroundColor: '#439553',
                borderRadius: '50%',
                opacity: 0.2,
                zIndex: 0
            }} />
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '30%',
                width: '80px',
                height: '80px',
                backgroundColor: '#439553',
                borderRadius: '50%',
                opacity: 0.2,
                zIndex: 0
            }} />
            <Box sx={{
                position: 'absolute',
                bottom: '30%',
                left: '5%',
                width: '120px',
                height: '120px',
                backgroundColor: '#439553',
                borderRadius: '50%',
                opacity: 0.2,
                zIndex: 0
            }} />
            <Box sx={{
                position: 'absolute',
                top: '70%',
                right: '25%',
                width: '60px',
                height: '60px',
                backgroundColor: '#439553',
                borderRadius: '50%',
                opacity: 0.2,
                zIndex: 0
            }} />

            <Grid container item xs={12} sx={{ height: '25vh', zIndex: 1 }}>
                <Grid item xs={12} x={{ height: '100%', pl: 25 }} display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
                    <Typography fontSize={80} fontWeight={700} lineHeight={1} color={"#439553"}>How we do it...</Typography>
                </Grid>
                <Grid item  xs={4}></Grid>
                <Grid item xs  sx={{ background: '#439553', borderTopLeftRadius: 5, borderBottomLeftRadius: 5, mt:{xs: 5,sm:2, md: 0,lg:0,xl: 0} }} display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
                    <Typography variant='h6' color="white" fontStyle={'italic'} ml={2}>
                        Revolutionizing Government Engagement: A Vision For Positivity.
                    </Typography>
                </Grid>
            </Grid>
            <Grid display={'flex'} item xs={12} justifyContent={'flex-end'} sx={{ zIndex: 1,mt:{xs: 20,sm:15, md: 0,lg:0,xl: 0}  }} >
                <Timeline position="alternate-reverse">
                    {timelineData.map((item, index) => (
                        <TimelineItem key={index}>
                            <TimelineSeparator>
                                <TimelineDot sx={{background:"#F19C1B"}} />
                                {index !== timelineData.length - 1 && (
                                    <TimelineConnector style={{ position: 'relative', backgroundColor: 'gray', height: '100px' }} />
                                )}
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper sx={{ background: '#2f3c42', padding: 2 }}>
                                    <Typography variant="h4" component="h2" color="green" gutterBottom>
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body1" component="p" color="white">
                                        {item.content}
                                    </Typography>
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
            </Grid>
        </Grid>
    );
}
