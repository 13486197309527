import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route,Routes} from 'react-router-dom';
import theme from './theme';
import DefaultPage from './Pages/defaultPage';
import AboutUs from './Pages/AboutUs';
import HowWeDoIt from './Pages/HowWeDoIt'
import WhoWeAre from './Pages/WhoWeAre';
import Media from './Pages/Media';
import ContactUs from './Pages/ContactUs';
import PageLayout from './Pages/PageLayout';
import LastLayout from './Pages/LastLayout';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
      <Routes>
        <Route element={<PageLayout />}>
          <Route path="/who-we-are" element={<WhoWeAre />} />
          <Route path="/how-we-do-it" element={<HowWeDoIt />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/strategic-media-partners" element={<Media />} />
          <Route path="/about-us" element={<AboutUs />} />
        </Route>
        <Route element={<LastLayout />}>
          <Route path="/" element={<DefaultPage />} />
        </Route>
      </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
