import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { Phone, Email, AccessTime, LocationOn } from '@mui/icons-material';
import logo from '../assets/logo.png';

const Footer = () => {
  return (
    <Grid container sx={{backgroundColor: '#f5f5f5' }}>
      <Grid container spacing={3} px={2}>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <img src={logo} alt="GSA Logo" style={{ width: 70, marginRight: 12 }} />
            <Typography variant="h6" color='#9AC558'>Govmark</Typography>
          </Box>
          <Typography variant="body2" mt={2}>
            Fully fledged and independent 100% black woman-owned marketing and media solutions company.
          </Typography>
          <Typography variant="body2" mt={1}>Level 1 B-BBEE Procurement Recognition.</Typography>
        </Grid>
        <Grid item xs={12} md={6}  mt={2}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AccessTime sx={{ mr: 1, color: '#A63B12' }} />
                <Typography variant="body2">M-F 8:00am - 5:00pm</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Phone sx={{ mr: 1, color: '#A63B12' }} />
                <Typography variant="body2">010 979 0008</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Email sx={{ mr: 1, color: '#A63B12' }} />
                <Typography variant="body2">admin@govmark.co.za</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <LocationOn sx={{ mr: 1, color: '#A63B12' }} />
                <Box>
                  <Typography variant="body2">Govmark</Typography>
                  <Typography variant="body2">
                    Thornhill Office Park, 94 Bekker Road, Vorna Valley, Midrand, 1686
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box width={"100%"} display='flex' sx={{ bgcolor: '#9AC558', py: 1 }} justifyContent='center' mt={2}>
        <Typography variant="subtitle2" align="center" color="textSecondary">
          GovMark (Pty) Ltd. All Rights Reserved.
        </Typography>
      </Box>
    </Grid>
  );
};

export default Footer;
