import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function CopywritingIcon({fill}) {
  return (
    <SvgIcon version="1.0" xmlns="http://www.w3.org/2000/svg"
    style={{
      width:'60%',
      height:'60%'
    }}
  viewBox="0 0 512 512"
 preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#439553" stroke="none">
<path d="M191 5106 c-52 -29 -50 6 -51 -984 0 -661 3 -930 11 -945 27 -52 80
-70 134 -45 57 26 55 -7 55 928 l0 860 1740 0 1740 0 0 -903 0 -902 -442 -442
-443 -443 -693 -2 -694 -3 -29 -33 c-16 -18 -29 -44 -29 -58 0 -37 25 -78 55
-92 19 -9 178 -12 607 -12 l582 0 -184 -188 -183 -187 -153 -350 -153 -350
-583 -5 c-634 -5 -615 -4 -639 -62 -16 -39 -3 -93 28 -118 26 -20 33 -20 1178
-20 l1152 0 27 21 c37 29 49 64 36 104 -23 70 -20 69 -348 75 l-297 5 160 69
160 69 442 441 442 440 3 -755 3 -754 26 -72 c15 -40 45 -99 67 -133 l41 -60
-1662 2 -1662 3 -59 24 c-81 33 -175 125 -207 206 l-24 60 -5 932 -5 933 -28
27 c-40 41 -99 39 -138 -5 l-29 -32 0 -913 c0 -1002 -1 -983 61 -1113 46 -95
168 -217 263 -263 135 -64 16 -61 2096 -61 2080 0 1962 -3 2097 61 94 44 216
167 262 263 58 123 61 149 61 698 l0 498 -29 32 -29 33 -451 3 -451 3 0 292 0
292 474 475 c316 316 477 484 481 502 14 57 -2 79 -239 316 l-232 232 -48 0
-49 0 -193 -192 -194 -192 0 863 c0 936 2 903 -55 929 -40 19 -3741 17 -3774
-2z m3617 -2853 l-928 -928 -140 140 -140 140 927 927 928 928 140 -140 140
-140 -927 -927z m-1111 -1036 c-2 -8 -354 -167 -369 -167 -6 0 30 84 136 324
l27 60 104 -104 c58 -58 104 -108 102 -113z m2081 -274 c-3 -447 -3 -448 -27
-508 -33 -83 -124 -173 -206 -205 -84 -33 -206 -34 -289 -1 -81 32 -174 125
-207 206 -24 60 -24 61 -27 508 l-3 447 381 0 381 0 -3 -447z"/>
<path d="M1786 4454 c-212 -38 -392 -142 -533 -308 -379 -446 -229 -1135 302
-1387 126 -59 202 -79 337 -86 227 -12 429 52 608 194 199 157 323 400 337
658 31 570 -490 1030 -1051 929z m349 -214 c122 -38 202 -87 296 -180 73 -72
94 -101 132 -180 60 -124 82 -231 74 -357 -20 -292 -214 -537 -497 -625 -108
-33 -292 -33 -400 0 -283 88 -477 333 -497 625 -8 128 14 234 74 357 39 79 60
109 132 180 111 111 229 173 379 200 75 14 228 4 307 -20z"/>
<path d="M1850 4019 c-25 -5 -77 -24 -116 -44 -207 -102 -305 -351 -224 -568
45 -121 158 -230 282 -274 38 -13 82 -18 153 -18 89 0 107 3 161 28 35 16 82
50 110 78 42 43 49 55 49 90 0 34 -6 46 -34 71 -29 26 -39 29 -74 25 -25 -3
-51 -16 -71 -35 -46 -44 -89 -62 -148 -62 -229 1 -341 278 -178 441 83 83 196
99 303 44 67 -34 111 -33 148 4 61 61 28 136 -79 184 -98 44 -184 55 -282 36z"/>
<path d="M204 2861 c-30 -13 -64 -59 -64 -88 0 -54 48 -103 100 -103 53 0 100
49 100 104 0 56 -83 109 -136 87z"/>
<path d="M878 2213 c-56 -35 -62 -123 -11 -163 35 -27 186 -29 219 -2 51 41
57 97 15 144 -28 32 -33 33 -113 36 -65 2 -89 -1 -110 -15z"/>
<path d="M878 1573 c-56 -35 -62 -123 -11 -163 25 -19 38 -20 409 -20 l384 0
30 28 c40 36 47 77 20 120 -11 18 -32 37 -46 42 -15 6 -177 10 -392 10 -326
-1 -370 -2 -394 -17z"/>
</g>
 </SvgIcon>
  );
}