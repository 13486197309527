import React from 'react'
import {Outlet} from 'react-router';
import {Grid} from '@mui/material'
import Footer from '../Components/Footer';
import Header from '../Components/Header';

export default function () {
  return (
    <Grid
      container
      display="flex"
      height="100vh"
      sx={{
        background:'linear-gradient(136deg, #2f3c42 40%, #439553 100%)'
      }}
    >
      <Grid container item xs={12} >
        <Header/>
      </Grid>
      <Grid container  item  xs={12}  overflow={'hidden'}    >
        <Outlet/>
    </Grid>
    <Footer/>
    </Grid>
  )
}
