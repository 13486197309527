import React from 'react';
import { Box } from '@mui/material';
import TypographySection from '../Components/HomeTypography';
import InfoBox from '../Components/InfoBox';
import bg_woman from '../assets/bg_home_w.jpeg';
import bbg from '../assets/light_G.png';
import bl from '../assets/light_eG.png';

const Home = () => (
  <Box
    sx={{
      background: 'linear-gradient(180deg, #2f3c42 45%, #439553 100%)',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      position: 'relative'
    }}
  >
    <Box
      color='#439553'
      sx={{
        marginLeft:5,
        display: 'flex',
        flexDirection: 'column',
        mt: { xs: 0,sm:0, md: 5,lg:5,xl:5 },
        py: { xs: 5, md: 10,lg:20,xl:40 },
        gap: { xs: 5, md: 15,lg:20,xl:40 }
      }}
    >
      <TypographySection />
      <Box display='flex' flexDirection='row' justifyContent='flex-end' alignItems='center' mr={2}>
        <InfoBox />
      </Box>
    </Box>

    <Box
      component="img"
      src={bg_woman}
      alt="background woman"
      sx={{
        position: 'absolute',
        top:'10%',
        right: 0,
        borderRadius: '10px',
        marginRight: '7%',
        marginBottom: '12%',
        zIndex: 1,
        mt:0,
        mb: { xs: 20, sm: 15 ,md:10, lg:5 },
        width: { xs: 200, sm: 350, md: 400, lg:600 , xl: 700}
      }}
    />
    <Box
      component="img"
      src={bbg}
      alt="background graphic"
      sx={{
        position: 'absolute',
        right: 0,
        top:'14%',
        borderRadius: '10px',
        marginRight: '6%',
        marginBottom: '8%',
         mb: { xs: 15,sm:10, md: 5,lg:0, xl:0},
        width: { xs: 100, sm: 175, md: 250, lg:350, xl: 400}
      }}
    />
    <Box
      component="img"
      src={bl}
      alt="background light"
      sx={{
        position: 'absolute',
        left: -100,
        bottom: -100,
        borderRadius: '50%',
        width: { xs: 200, sm: 300, md: 360, lg:360, xl:420}
      }}
    />
  </Box>
);

export default Home;
