import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function ReputationalManagementIcon({fill}) {
  return (
    <SvgIcon version="1.0" xmlns="http://www.w3.org/2000/svg"
    style={{
      width:'60%',
      height:'60%'
    }}
  viewBox="0 0 512 512"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#439553" stroke="none">
<path d="M546 4949 c-62 -15 -153 -68 -197 -116 -22 -24 -55 -74 -72 -111
l-32 -67 0 -575 0 -575 32 -67 c44 -93 100 -151 191 -196 l76 -37 2016 0 2016
0 76 37 c91 45 147 103 191 196 l32 67 0 575 0 575 -32 67 c-44 93 -100 151
-191 196 l-76 37 -1995 2 c-1182 0 -2012 -3 -2035 -8z m4008 -161 c55 -16 138
-99 154 -154 17 -59 17 -1049 0 -1108 -16 -55 -99 -138 -154 -154 -60 -18
-3928 -18 -3988 0 -26 8 -60 31 -91 63 -32 31 -55 65 -63 91 -17 59 -17 1049
0 1108 15 51 99 137 148 153 53 17 3937 17 3994 1z"/>
<path d="M1162 4547 c-12 -7 -56 -63 -97 -124 -81 -121 -68 -113 -220 -153
-118 -31 -153 -80 -103 -146 11 -16 50 -58 84 -94 l64 -65 -40 -130 c-22 -72
-40 -144 -40 -161 0 -37 36 -74 71 -74 14 0 89 27 167 60 77 33 146 60 152 60
6 0 75 -27 152 -60 78 -33 154 -60 168 -60 35 0 70 38 70 76 0 16 -18 87 -40
159 l-40 130 64 65 c82 85 106 118 106 146 0 37 -40 71 -102 88 -185 51 -161
35 -244 161 -87 131 -117 152 -172 122z m88 -282 c53 -82 77 -101 146 -116 30
-7 54 -15 54 -19 0 -3 -21 -26 -46 -50 -65 -63 -68 -78 -44 -168 11 -41 19
-77 16 -79 -2 -2 -36 9 -76 26 -40 17 -85 31 -100 31 -15 0 -60 -14 -99 -32
-39 -17 -74 -29 -77 -25 -3 3 4 37 17 75 12 39 19 79 16 93 -4 13 -27 47 -52
76 -47 54 -52 63 -34 63 6 0 35 7 65 16 52 15 58 20 107 95 29 43 55 79 58 79
4 0 26 -29 49 -65z"/>
<path d="M2522 4547 c-12 -7 -56 -63 -97 -124 -81 -121 -68 -113 -220 -153
-118 -31 -153 -80 -103 -146 11 -16 50 -58 84 -94 l64 -65 -40 -130 c-22 -72
-40 -144 -40 -161 0 -37 36 -74 71 -74 14 0 89 27 167 60 77 33 146 60 152 60
6 0 75 -27 152 -60 78 -33 154 -60 168 -60 35 0 70 38 70 76 0 16 -18 87 -40
159 l-40 130 64 65 c82 85 106 118 106 146 0 37 -40 71 -102 88 -185 51 -161
35 -244 161 -87 131 -117 152 -172 122z m88 -282 c53 -82 77 -101 146 -116 30
-7 54 -15 54 -19 0 -3 -21 -26 -46 -50 -65 -63 -68 -78 -44 -168 11 -41 19
-77 16 -79 -2 -2 -36 9 -76 26 -40 17 -85 31 -100 31 -15 0 -60 -14 -99 -32
-39 -17 -74 -29 -77 -25 -3 3 4 37 17 75 12 39 19 79 16 93 -4 13 -27 47 -52
76 -47 54 -52 63 -34 63 6 0 35 7 65 16 52 15 58 20 107 95 29 43 55 79 58 79
4 0 26 -29 49 -65z"/>
<path d="M3882 4547 c-12 -7 -56 -63 -97 -124 -81 -121 -68 -113 -220 -153
-118 -31 -153 -80 -103 -146 11 -16 50 -58 84 -94 l64 -65 -40 -130 c-22 -72
-40 -144 -40 -161 0 -37 36 -74 71 -74 14 0 89 27 167 60 77 33 146 60 152 60
6 0 75 -27 152 -60 78 -33 154 -60 168 -60 35 0 70 38 70 76 0 16 -18 87 -40
159 l-40 130 64 65 c82 85 106 118 106 146 0 37 -40 71 -102 88 -185 51 -161
35 -244 161 -87 131 -117 152 -172 122z m88 -282 c53 -82 77 -101 146 -116 30
-7 54 -15 54 -19 0 -3 -21 -26 -46 -50 -65 -63 -68 -78 -44 -168 11 -41 19
-77 16 -79 -2 -2 -36 9 -76 26 -40 17 -85 31 -100 31 -15 0 -60 -14 -99 -32
-39 -17 -74 -29 -77 -25 -3 3 4 37 17 75 12 39 19 79 16 93 -4 13 -27 47 -52
76 -47 54 -52 63 -34 63 6 0 35 7 65 16 52 15 58 20 107 95 29 43 55 79 58 79
4 0 26 -29 49 -65z"/>
<path d="M2430 3024 c-266 -57 -470 -311 -470 -584 0 -324 276 -600 600 -600
276 0 528 205 585 475 43 204 -16 397 -164 546 -149 149 -347 207 -551 163z
m222 -155 c223 -48 379 -274 341 -495 -33 -190 -177 -334 -367 -367 -283 -48
-547 215 -499 498 43 253 279 416 525 364z"/>
<path d="M2083 1749 c-259 -43 -477 -246 -543 -505 -18 -69 -20 -111 -20 -393
0 -267 2 -320 15 -338 9 -12 52 -46 98 -76 444 -293 1013 -357 1514 -171 184
68 420 208 443 263 9 22 11 114 8 353 -4 303 -6 327 -27 393 -63 193 -210 354
-396 432 -62 26 -189 53 -250 53 -45 0 -47 -2 -201 -147 -85 -82 -159 -149
-163 -151 -4 -1 -78 65 -164 148 l-156 150 -53 -1 c-29 -1 -76 -5 -105 -10z
m265 -313 c93 -91 181 -168 195 -171 14 -4 36 -1 49 6 12 6 96 83 185 171
l163 159 47 -7 c166 -24 314 -127 393 -275 52 -98 60 -155 60 -452 l0 -267
-67 -44 c-372 -240 -858 -300 -1289 -161 -102 34 -265 111 -343 164 l-61 42 0
263 c0 145 5 287 10 317 13 70 61 171 107 227 83 101 239 183 365 191 10 1 93
-72 186 -163z"/>
</g>
</SvgIcon>
  );
}
