import React from 'react';
import { Box, Typography } from '@mui/material';

const InfoBox = () => (
  <Box
    sx={{
      backgroundColor: '#232C31',
      borderRadius: 5,
      width: '70%',
      p: 2,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <Typography
      fontSize={16}
      sx={{ cursor: 'pointer', textAlign: 'center' }}
    >
      The people you want to talk to are those who interact with you through various platforms, channels, and ever-evolving technologies, the landscape has changed.
      We have a pulse on where people are and are nimble, agile, and adaptive. Our approach is broad and multifaceted, and our thinking is diverse and goal-oriented.
      We can offer you a marketing and media solution that will work, whether it be for traditional media or the ever-changing digital landscape.
    </Typography>
  </Box>
);

export default InfoBox;
