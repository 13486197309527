import React from 'react'
import { Outlet } from 'react-router-dom'
import {Grid} from '@mui/material'
import Header from '../Components/Header';

export default function LastLayout() {

  return (
     <Grid
      container
      display="flex"
      height="100vh"
      bgcolor="linear-gradient(136deg, #2f3c42 40%, #439553 100%)"
    >
      <Grid container item xs={12}  sx={{background:"linear-gradient(120deg, #2f3c42 100%, #439553 100%)"}} >
        <Header/>
      </Grid>
      <Grid container  item  xs={12}  overflow={'hidden'}    >
        <Outlet/>
    </Grid>
    </Grid>
  )
}
