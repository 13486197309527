import React from 'react';
import Box from '@mui/material/Box';
import { Typography, Grid, IconButton } from '@mui/material';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import m1 from '../assets/Media/c.png';
import m2 from '../assets/Media/c.png';
import m3 from '../assets/Media/ds.png';
import m4 from '../assets/Media/er.png';
import m5 from '../assets/Media/g.png';
import m6 from '../assets/Media/h.png';
import m7 from '../assets/Media/j.png';
import m8 from '../assets/Media/p.png';
import m9 from '../assets/Media/pb.png';
import m10 from '../assets/Media/po.png';
import m11 from '../assets/Media/sabc.png';
import m12 from '../assets/Media/y.png';
import m13 from '../assets/Media/yf.png';
import m14 from '../assets/second_media/a.png';
import m15 from '../assets/second_media/b.png';
import m16 from '../assets/second_media/c.png';
import m17 from '../assets/second_media/d.png';
import m18 from '../assets/second_media/e.png';
import m19 from '../assets/second_media/pod.png';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      style={{
        position: 'absolute',
        top: '35%',
        right: 0,
        transform: 'translateY(-50%)',
        color: 'gray',
        zIndex: 2
      }}
      onClick={onClick}
      size='large'
    >
      <ChevronRight />
    </IconButton>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      style={{ 
        position: 'absolute', 
        top: '35%', 
        left: -40, 
        transform: 'translateY(-50%)', 
        color: 'gray',
        zIndex: 2
      }}
      onClick={onClick}
      size='large'
    >
      <ChevronLeft />
    </IconButton>
  );
};

const Media = () => {
  const media = [m1, m2, m3, m4, m15, m16, m7, m8, m9, m10, m11, m12, m13, m14, m5, m6, m17, m18,m19];

  const settings = {
    arrows: true,
    dots:false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          autoplay: true,
          autoplaySpeed: 2500,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2500,
        }
      }
    ]
  };

  return (
    <Grid container xs sx={{ background: 'linear-gradient(to bottom, #e0f7fa 35%, white)' }} p={2} rowGap={4} height={'70vh'}>
    
        <Grid item xs={12} md={12} display={'flex'} alignItems={'center'}>
          <Typography fontSize={{ xs: 40, md: 80 }} fontWeight={700} color={"#439553"}>
            Strategic Media Partners
          </Typography>
        </Grid>
        <Grid item xs display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Typography variant="h5" color="textSecondary" fontSize={25}>
            Boasting a 13 year long enjoyment and track record, partnering with some of the TOP media brands in South Africa!
          </Typography>
        </Grid>
      <Grid container item xs justifyContent={'center'} alignItems={'center'}>
        <Box width={'90%'} position="relative">
          <Slider {...settings} style={{marginLeft:20}}>
            {media.map((image, index) => (
              <Grid container item xs={6} key={index} alignItems={'center'} ml={8}>
                <img className='scroll-image' src={image} alt='media_logo' style={{ width: '80%', height: 'auto' }} />
              </Grid>
            ))}
          </Slider>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Media;
